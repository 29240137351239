$category-flex: 1;
$time-category-flex: 0.75;
$destination-category-flex: 2;
$security-category-flex: 1.75;
$source-category-flex: 1.5;
$severity-category-flex: 0.7;
$more-category-flex: 0.4;
$bot-response-category-flex: 1.1;
$bot-code-category-flex: 1;
$transaction-status-category-flex: 0.7;
$status-category-flex: 0.9;
$action-category-flex: 0.6;
$attack-vector-category-flex: 1.2;
$list-header-height: 45px;
$filter-header-height: $list-header-height;

$item-padding-left: 20px;
$item-padding-right: 10px;

$list-header-padding-left: $item-padding-left + 5px;

$blue-background-color: #f7fdff;

$orange: #ffc757;
$captcha: #ff9a01;
$red: #f3858d;
$pink-red: #ff2b44;
$blue: #8399dd;
$green: #7ad072;
$throttle-green: #19999b;
$crimson: #c80d51;
$light-purple: #9e67ea;
$bright-purple: #7F48FD;
$cornflower-blue: #6d8bec;
$cinnabar: #41dee0;
$peach: #f44e8b;
$violet: #641bc9;
$sky-blue: #26b1ff;
$navy-blue: #211dd9;
$pink: #FF75A8;
$dark-green: #00A5A7;

$transparency: 0.5;

$critical: #c0202b;

$high: #f09426;

$warning: #ffd600;

$low: #fff3c9;

$info: #f4f4f4;

%severity-styles {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    min-width: 80px;
    min-height: 24px;
    font-weight: 500 !important;
    border-radius: 24px;
    font-size: 13px;
    line-height: 1;
    &.Critical {
        background-color: $critical;
        color: $white;
    }

    &.High {
        background-color: $high;
        color: $white;
        font-weight: 400;
    }

    &.Warning {
        background-color: $warning;
        color: $new_blue_400;
    }

    &.Low {
        background-color: $low;
        color: $new_blue_400;
    }

    &.Info {
        background-color: $info;
        color: $new_blue_400;
    }
}

.security-events {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $page-padding $page-padding 0 $page-padding;

    .security-events-header {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        margin-bottom: 24px;

        .header {
            display: flex;
            align-items: baseline;
        }

        .security-events-header-label {
            position: relative;

            h1 {
                @extend %page-header-title;
            }

            .beta {
                position: absolute;
                top: -5px;
                right: -25px;

                color: #ffb076;
                font-weight: 500;
                opacity: 0.9;
            }
        }

        .event-type-selector {
            margin-left: 35px;
        }

        .show-filter {
            background: url('../static-assets/images/filter-open.svg');
            width: 29px;
            height: 29px;
            margin-left: 15px;
            border: none;
        }

        .show-filter:focus {
            outline: 0;
        }

        .sub-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 10px;
            margin-bottom: 8px;

            .inner-navigator .radio-group {
                margin-bottom: 0;
                border: none;
            }
            .header-right {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin-bottom: 4px;

                .total-events-wrapper {
                    position: absolute;
                    bottom: -23px;
                    right: 0;
                }

                .sort-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 10px;

                    .Select {
                        min-width: unset;
                        width: 220px;
                        margin: 0 5px;
                    }

                    .asc,
                    .desc {
                        width: 27px;
                        height: 26px;
                        cursor: pointer;
                        background: url(../static-assets/images/sort-direction.svg) no-repeat center center;
                    }
                    .desc {
                        transform: rotate(180deg);
                    }
                }

                .refresh {
                    width: 30px;
                    height: 30px;
                    background: url(../static-assets/images/icon-refresh.svg) no-repeat center center;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 6.52px;
                    border: 1px solid $blue-background-color;
                    outline: 1px solid #cfd2d3;
                    margin-left: 10px;
                }
                .export-button {
                    margin-top: 2px;
                }
            }
        }
    }

    .content-wrapper {
        display: flex;
        overflow: hidden;
        height: 100%;
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-width: 0;
        margin-bottom: 10px;
        background-color: var(--table-bg-color);
        border-radius: var(--table-border-radius);
        padding-left: var(--table-side-gap);
        padding-right: var(--table-side-gap);
        overflow: hidden;

        .list-header {
            display: flex;
            min-height: $list-header-height;
            padding: 12px 25px 0 $list-header-padding-left;
            border-bottom: 1px solid var(--table-row-border-color);

            &.web-ddos-header {
                .web-ddos-action-category {
                    flex: $action-category-flex;
                }

                .web-ddos-attack-vector-category {
                    flex: $attack-vector-category-flex;
                }

                .web-ddos-time-category {
                    padding-left: 15px;
                }
            }

            .category {
                flex: $category-flex;
                min-width: 0;
                word-wrap: break-word;

                .category-header label {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: var(--text-color);
                    margin: 0;
                }

                .filter-group {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;

                    .filter-property {
                        align-self: flex-start;
                        display: flex;
                        align-items: center;
                        background-color: var(--card-summary-bg-color);
                        margin: 2px 10px 2px 0;

                        div {
                            margin: 0 10px;
                        }

                        button {
                            width: 8px;
                            height: 8px;
                            padding: 0;
                            border: none;
                            margin-left: 10px;
                            background: url('../static-assets/images/remove-filter-icon.svg');
                        }
                    }
                }
            }

            .bot-response-category {
                flex: $bot-response-category-flex;
            }

            .bot-code-category {
                flex: $bot-code-category-flex;
            }

            .transaction-status-category {
                flex: $transaction-status-category-flex;
            }

            .time-category {
                flex: $time-category-flex;
            }

            .destination-category {
                flex: $destination-category-flex;
            }

            .source-category {
                flex: $source-category-flex;
            }

            .security-category {
                flex: $security-category-flex;
            }

            .severity-category {
                flex: $severity-category-flex;
            }

            .more-category {
                flex: $more-category-flex;
                text-align: center;
            }
        }

        .list {
            flex: 1;
            overflow-y: auto; // fallback
            overflow-y: overlay;
            padding-right: 15px;

            .Collapsible {
                border: none;
                color: inherit;
                border-bottom: 1px solid var(--table-row-border-color);

                .Collapsible__contentInner,
                .Collapsible__contentOuter,
                .Collapsible__trigger {
                    color: inherit;
                    border: none;
                    padding: 0;
                    font-size: inherit;
                    font-weight: inherit;
                    border-radius: inherit;
                }

                .Collapsible__contentInner {
                    background-color: var(--table-expanded-row-bg-color);
                }

                .Collapsible__trigger:hover {
                    .item {
                        background-color: var(--table-row-hover-bg-color);
                    }
                }

                &.open {
                    .Collapsible__trigger {
                        .item {
                            background-color: var(--table-row-active-bg-color);
                        }
                    }
                }
            }

            .Modified {
                border-left: 5px solid $orange;

                .action-category {
                    .action {
                        color: $orange;
                    }
                }
            }

            .Reported {
                border-left: 5px solid $blue;

                .action-category {
                    .action {
                        color: $blue;
                    }
                }
            }

            .Custom {
                border-left: 5px solid $sky-blue;

                .bot-response-category {
                    .action {
                        color: $sky-blue;
                    }
                }
            }

            .Log {
                border-left: 5px solid $peach;

                .bot-response-category {
                    .action {
                        color: $peach;
                    }
                }
            }

            .Redirect {
                border-left: 5px solid $cinnabar;

                .bot-response-category {
                    .action {
                        color: $cinnabar;
                    }
                }
            }

            .Complex {
                border-left: 5px solid $cornflower-blue;

                .bot-response-category {
                    .action {
                        color: $cornflower-blue;
                    }
                }
            }

            .JSChallenge {
                border-left: 5px solid $dark-green;

                .bot-response-category {
                    .action {
                        color: $dark-green;
                    }
                }
            }

            .Session {
                border-left: 5px solid $light-purple;

                .bot-response-category {
                    .action {
                        color: $light-purple;
                    }
                }
            }

            .Drop {
                border-left: 5px solid $crimson;

                .bot-response-category {
                    .action {
                        color: $crimson;
                    }
                }
            }

            .Throttle {
                border-left: 5px solid $throttle-green;

                .bot-response-category {
                    .action {
                        color: $throttle-green;
                    }
                }
            }

            .Feed {
                border-left: 5px solid $violet;

                .bot-response-category {
                    .action {
                        color: $violet;
                    }
                }
            }

            .Captcha {
                border-left: 5px solid $captcha;

                .bot-response-category {
                    .action {
                        color: $captcha;
                    }
                }
            }

            .Allow {
                border-left: 5px solid $green;

                .bot-response-category {
                    .action {
                        color: $green;
                    }
                }
            }

            .Blocked {
                border-left: 5px solid $red;

                .bot-response-category {
                    .action {
                        color: $red;
                    }
                }
            }

            .MobileSDK {
                border-left: 5px solid $bright-purple;

                .bot-response-category {
                    .action {
                        color: $bright-purple;
                    }
                }
            }

            .MobileAttestation {
                border-left: 5px solid $pink;

                .bot-response-category {
                    .action {
                        color: $pink;
                    }
                }
            }

            .blocked,
            .Blocked {
                border-left: 5px solid $red;

                .action-category {
                    .action {
                        color: $red;
                    }
                }
            }

            .item {
                display: flex;
                align-items: center;
                height: $list-large-item-height;
                background-color: var(--table-bg-color);
                padding: 0 $item-padding-right 0 $item-padding-left;
                word-wrap: break-word;

                &.web-ddos-item {
                    padding: 0 25px 0 25px;

                    .item-col div {
                        font-size: 12px !important;

                        &:first-child {
                            font-weight: 400;
                        }
                    }

                    .web-ddos-action-category {
                        flex: $action-category-flex;
                        color: var(--security-events-action-blocked-color);
                        font-weight: 600;
                    }

                    .web-ddos-attack-vector-category {
                        flex: $attack-vector-category-flex;
                    }

                    .web-ddos-time-category {
                        padding-left: 15px;
                    }
                }

                img {
                    margin-right: 10px;
                }

                .item-col {
                    display: flex;
                    flex: $category-flex;
                    min-width: 0;
                    flex-direction: column;

                    div {
                        flex: 1;
                        min-height: 0;
                        display: flex;
                        align-items: center;
                    }

                    div:first-child {
                        font-weight: 600;
                    }

                    .country-col {
                        @extend %text-no-overflow;
                        display: block;
                        max-width: calc(100% - 20px);
                        margin-top: 5px;
                    }

                    .severity {
                        @extend %severity-styles;
                    }

                    .multiple_items_container {
                        justify-content: flex-start;
                        max-width: calc(100% - 10px);
                        > div {
                            margin-right: 15px;
                            display: block;
                            @extend %text-no-overflow;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    .destination-host {
                        max-width: calc(100% - 10px);
                        span {
                            @extend %text-no-overflow;
                        }
                    }
                }

                .action-category {
                    .action-id-wrapper {
                        margin-right: 8px;
                        .action-id {
                            display: block;
                            flex-grow: 0;
                            flex-basis: auto;
                            font-weight: inherit;
                            @extend %text-no-overflow;
                        }
                    }
                }

                .bot-response-category {
                    flex: $bot-response-category-flex;
                    .action-id-wrapper {
                        margin-right: 8px;
                        .action-id {
                            display: block;
                            flex-grow: 0;
                            flex-basis: auto;
                            font-weight: inherit;
                            @extend %text-no-overflow;
                        }
                    }
                }

                .transaction-status-category {
                    flex: $transaction-status-category-flex;

                    .transaction-status {
                        &-Mitigated,
                        &-Reported {
                            display: inline-block;
                            height: 10px;
                            width: 10px;
                            margin-right: 5px;
                            border-radius: 50%;
                        }
                        &-Mitigated {
                            background-color: $pink-red;
                        }
                        &-Reported {
                            background-color: #3b77ee;
                        }
                    }
                }

                .time-category {
                    flex: $time-category-flex;
                }

                .destination-category {
                    flex: $destination-category-flex;
                }

                .source-category {
                    flex: $source-category-flex;
                }

                .security-category {
                    flex: $security-category-flex;
                    position: relative;
                }

                .severity-category {
                    flex: $severity-category-flex;
                }

                .status-category {
                    flex: $status-category-flex;
                }

                .more-category {
                    flex: $more-category-flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        flex: none;
                        width: 20px;
                        height: 20px;
                        background: url('../static-assets/images/more.svg');
                        transition: all 0.2s;
                    }
                }
            }

            .Collapsible__trigger.is-open {
                .more-category {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }

            .Collapsible__contentInner.details-wrapper {
                a {
                    display: block;
                }

                label {
                    margin-right: 5px;
                    margin-bottom: 0;
                    font-weight: 600;
                }

                .transaction-id {
                    font-size: 14px;
                    padding: 20px 20px 0 20px;
                }

                .event-details-row {
                    .info-icon {
                        display: inline-block;
                        background: url('../static-assets/images/info-icon-white.svg') no-repeat;
                        background-size: contain;
                        width: 15px;
                        height: 15px;
                        margin-right: 1px;
                        margin-left: 4px;
                        vertical-align: top;
                    }

                    .row-data,
                    .source-ip-wrapper {
                        display: inline-flex;
                        align-items: center;
                    }
                }
                .event-details-row .filterable {
                    text-decoration: underline;
                    cursor: pointer;
                }
                .event-details-row .clickable {
                    cursor: pointer;
                }

                .content-signature-value {
                    font-weight: 500;
                    color: #3b77ee;
                }
                .content-signature-expired {
                    color: $red-100;
                    font-weight: 400;
                    font-size: 12px;
                }

                .details {
                    display: flex;
                    padding: 10px 20px;
                    font-size: 12px;

                    .highlight {
                        color: red;
                        background-color: inherit;
                        font-weight: 600;
                    }

                    .details-col {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        min-width: 330px;
                        word-wrap: break-word;
                        white-space: pre-wrap;

                        &:not(:first-child) {
                            margin-left: 20px;
                        }

                        &.raw-http-category {
                            flex-grow: 2;
                            .details-content {
                                line-height: 1.6;
                            }
                        }

                        .details-title {
                            font-size: 14px;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }

                        .details-content {
                            height: 100%;
                            box-shadow: 0px 0px 2px #bdbdbd;
                            border-radius: 4px;
                            padding: 10px 15px;

                            .dashboard-link {
                                display: inline;
                                padding: 0px 5px;
                                font-size: 14px;
                                font-weight: bold;
                                cursor: pointer;
                            }
                            .event-details-row {
                                margin-bottom: 5px;

                                .highlight-container {
                                    display: initial;
                                    white-space: initial;
                                }

                                .external-references a {
                                    @extend %text-no-overflow;
                                }
                            }

                            .source-IP-Geo-Loc-value img {
                                margin-right: 10px;
                                vertical-align: text-bottom;
                            }

                            .severity {
                                @extend %severity-styles;
                                min-width: 60px;
                                min-height: 14px;
                                font-size: 11px;
                            }

                            .source-ip-wrapper {
                                margin-left: 5px;

                                .source-ip-sum {
                                    height: 20px;
                                    min-width: 20px;
                                    border-radius: 4px;
                                    padding: 3px 6px;
                                    font-weight: 400;
                                    font-size: 10px;
                                    line-height: 12px;
                                    color: var(--badge-text-color);
                                    background-color: var(--badge-bg-color);
                                    cursor: pointer;
                                }
                            }

                            &.attack-story {
                                padding: 12px 14px;
                                background-color: var(--as-section-bg-color);
                            }
                        }
                    }

                    .loader {
                        display: inline-block;
                        width: 15px;
                        height: 15px;
                        margin: unset;
                    }
                }

                .event-buttons {
                    padding: 10px 20px;
                    display: flex;
                    align-items: flex-end;

                    .confirmation {
                        display: flex;
                        align-items: center;

                        .confirmation-message {
                            margin-right: 10px;
                        }

                        .cancel {
                            background-color: #717171;
                            outline: 1px solid #717171;
                        }
                    }

                    .allow-request-message {
                        display: flex;
                        margin-top: 14px;

                        .success-icon {
                            background: url('../static-assets/images/success_indication.svg');
                            background-size: cover;
                            width: 16px;
                            margin-left: 6px;
                            margin-right: 3px;
                            margin-bottom: 4px;
                        }

                        .success-message {
                            font-size: 14px;
                            margin-right: 15px;
                            color: #00b168;
                        }

                        .status {
                            font-size: 14px;
                            margin-left: 15px;
                        }
                    }

                    .routing-section {
                        display: flex;
                        flex-direction: column;
                        align-content: flex-end;
                        gap: 15px;

                        .routing-link {
                            font-size: 12px;
                            font-weight: 500;
                            cursor: pointer;
                            color: #3b77ee;
                            margin-left: 11px;
                            display: flex;
                            flex-direction: row;
                            text-align: center;

                            .arrow-icon {
                                background: url('../static-assets/images/new-design/arrow-blue-right.svg');
                                background-size: cover;
                                margin-left: 9px;
                                width: 19px;
                                height: 16px;
                            }
                        }
                    }

                    button {
                        font-size: 14.3px;
                        color: #f7fdff;
                        padding: 5px 10px;
                        border: 1px solid #f7fdff;
                        outline: 1px solid #6ec089;
                        background-color: #6ec089;
                        margin-right: 10px;

                        &.go-to-api-config {
                            outline: 1px solid #71879e;
                            background-color: #71879e;
                        }

                        &.refine-api-suggestion {
                            outline: 1px solid #f4ac76;
                            background-color: #f4ac76;
                        }
                    }

                    .skip-js {
                        outline: 1px solid #6596b6;
                        background-color: #6596b6;
                    }

                    .skip-mobile-sdk {
                        outline: 1px solid #6596b6;
                        background-color: #6596b6;
                    }

                    .skip-mobile-attestation {
                        outline: 1px solid #6596b6;
                        background-color: #6596b6;
                    }

                    .refine-status-wrapper {
                        span {
                            margin-right: 5px;
                        }

                        .IN_PROCESS {
                            color: $in-progress-color;
                        }
                        .SUCCESS {
                            color: $success-color;
                        }
                        .FAIL {
                            color: $error-color;
                        }

                        .refine-status:before {
                            content: '';
                            display: inline-block;
                            height: 16px;
                            width: 20px;
                            background: 0 0 no-repeat;
                            background-size: 16px;
                        }
                        .IN_PROCESS:before {
                            background-image: url('../static-assets/images/pending_indication.svg');
                        }
                        .SUCCESS:before {
                            background-image: url('../static-assets/images/success_indication.svg');
                        }
                        .FAIL:before {
                            background-image: url('../static-assets/images/failed-red-icon.svg');
                        }
                    }
                }
            }
        }
    }

    .filter-wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 5px;

        .filter-header {
            display: flex;
            height: $filter-header-height;
            align-items: center;

            .filter-label {
                font-size: 1.3em;
                margin-left: 10px;
                color: #717171;
                font-weight: 500;
            }

            .filter-header-icon {
                background: url('../static-assets/images/filter-open.svg');
                background-size: cover;
                width: 18px;
                height: 18px;
            }

            .discard {
                border: none;
                background: none;
                margin-left: auto;
                font-size: 0.923em;
                color: #6da7c8;
            }

            .apply {
                width: 62px;
                height: 24px;
                color: $white;
                margin-left: 10px;
                margin-right: 1px;
                font-size: 0.923em;
                background-color: #6da7c8;
                border: 1px solid $blue-background-color;
                outline: 1px solid #6da7c8;
            }
        }

        .filter-body-wrapper {
            flex: 1;
            overflow-y: auto;
            width: 330px;
            margin-bottom: 10px;
            border-radius: var(--card-border-radius);

            .filter {
                width: 100%;
                min-height: 100%;
                padding: 12px 15px;
                background-color: var(--card-bg-color);
                border-radius: inherit;

                > input[type='text'] {
                    width: 100%;
                    height: 32px;
                    color: $black;
                    border-radius: 0;
                }

                .category-label {
                    margin-top: 15px;
                    margin-bottom: 5px;
                    font-size: 1.1em;
                    font-weight: 600;
                }

                .dynamic-select-element-wrapper {
                    display: flex;
                    min-width: 250px;
                    margin-bottom: 20px;
                }

                .action-group-transaction {
                    display: flex;
                    gap: 11px;
                    margin-top: 7px;
                }

                .action-group-policy-list {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                .action-group {
                    display: flex;

                    .action-group-item:first-child label {
                        color: $orange;
                    }

                    .action-group-item:nth-child(2) label {
                        color: $blue;
                    }

                    .action-group-item:last-child label {
                        color: $red;
                    }

                    .action-group-item {
                        margin: auto;
                    }

                    .action-group-item:first-child {
                        margin-left: 0;
                    }

                    .action-group-item:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .filter input[type='text'] {
        width: 100%;
    }
}

.country-label {
    margin-left: 5px;
}

/* IE11 hacks*/
_:-ms-fullscreen,
:root {
    .security-events-header {
        justify-content: space-between;
        .header-right {
            .total-events-wrapper {
                right: 33%;
            }
        }
    }
}

.unblock-modal-text {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
}

.ssrf-refine-form-container {
    .ssrf-refine-input-field {
        display: flex;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        color: #687077;
        font-size: 18px;
        align-items: baseline;
        font-weight: 400;

        .checkbox-label {
            text-transform: initial;
        }
    }

    .ssrf-refine-error-field {
        height: 30px;

        span {
            float: none;
        }
    }
}

.blaRefineModal {
    .modal-body,
    .modal-content {
        overflow-y: visible;
    }
}
.bla-security {
    &-confirmation {
        color: var(--text-color);
        font-size: 12px;
        line-height: 18px;
    }
    .bla-radio {
        margin-top: 10px;
    }
    .information-tooltip-icon-container {
        word-wrap: break-word;
    }
    &-indicator {
        display: flex;
        flex-direction: column;
        .iprule-list {
            margin: 10px 0px 10px 16px;
            max-width: 210px;
            font-weight: 400;

            span.invalid-input {
                font-size: 10px;
                float: none;
                white-space: nowrap;
            }

            .Select {
                min-width: 210px;
                max-width: 300px;
            }
        }

        .item {
            display: flex;
            gap: 10px;
            color: var(--text-color);

            &-icon {
                width: 30px;
                height: 30px;
                border-radius: 4px;
                background-color: var(--bla-dashboard-item-icon-bg-color);
                background-origin: border-box;
                background-repeat: no-repeat;
                background-position: center center;
            }
            .actor-icon {
                background-image: var(--actor-card-icon);
            }
            .ip-icon {
                background-image: var(--ip-icon);
            }
            .rules-icon {
                background-image: var(--rules-icon);
            }
            &-summary {
                font-size: 12px;
                line-height: 14px;
                display: flex;
                flex-direction: column;
                gap: 3px;
            }
            &-value {
                font-weight: 500;
            }
        }
    }
}
