.sslApplicationManagement-details {
    padding: 20px 0;

    table {
        width: 100%;
        table-layout: fixed;
    }

    .chartjs-render-monitor {
        max-height: 200px !important;
    }
}

.sslApplicationManagement-details-fields-domain {
    padding: 40px 0 40px 0;
}

.sslApplicationManagement-details-fields-domain input {
    width: 380px;
}

.sslApplicationManagement-details-fields-domain p {
    padding: 0;
    margin: 0;
}

.sslApplicationManagement-details-applicationDetailsGrid {
    float: left;
}

.sslApplicationManagement-details-applicationDetailsGrid h4 {
    font-size: 13px;
    margin-bottom: 0;
    border-bottom: 1px solid;
}

.details-table-item td {
    vertical-align: baseline;
}

.details-table-item td:first-child {
    padding-right: 8px;
    font-weight: bold;
    font-size: 0.923em;
}

.application-details {
    width: 100%;
}

.sslApplicationManagement-details {
    .sslApplicationManagement-details-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .details-summary-column,
        .protection-summary-column {
            flex: 1;
            max-width: 800px;
        }

        .details-summary-column {
            margin-bottom: 40px;
            margin-right: 40px;
            min-width: 500px;
        }

        .protection-summary-column {
            min-width: 600px;
        }

        .protection-summary-column-left {
            margin-top: 50px;

            .protection-summary {
                margin-top: 20px;
            }
        }
    }

    .image-button {
        width: 24px;
        height: 24px;
        opacity: 0.6;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        &:not(:last-child) {
            margin-right: 15px;
        }

        &.icon-view {
            background-image: url(../static-assets/images/icon-view.svg);
        }

        &.icon-settings {
            background-image: url(../static-assets/images/settings.svg);
        }
    }

    .details-summary {
        display: flex;
        flex-wrap: wrap;

        .details-item {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            font-weight: 400;
            white-space: nowrap;
            color: var(--text-color);
            font-size: 13px;

            .details-item-type {
                padding-right: 8px;
                color: var(--text-color);
                font-size: 13px;
                font-weight: 600;
            }
        }

        .application-update-form-details-app-domain-grid {
            margin-right: 40px;
            margin-bottom: 20px;
        }

        .sslApplicationManagement-details-applicationDetailsGrid {
            margin: 0;
            margin-bottom: 20px;

            h4 {
                color: var(--text-color);
                margin: 0;
                margin-bottom: 5px;
            }
        }
    }

    .origin-status {
        background: var(--card-bg-color);
        border-radius: var(--card-border-radius);
        padding: 15px;
        font-weight: 500;
        font-size: 14px;
        color: var(--text-color);

        .origin-status-header {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .origin-status-header-title {
                flex: 1;
                font-size: 18px;
            }
        }

        .origin-status-list {
            display: grid;
            gap: 10px;
            grid-template-columns: minMax(0, 50%) minMax(0, 50%);
            max-width: 100%;

            .origin-status-item {
                display: flex;
                align-items: center;
                background: var(--app-summary-item-bg-color);
                border: 1px solid var(--card-separator-color);
                border-radius: 24px;
                height: 30px;
                padding: 5px 20px 5px 6px;

                .origin-status-item-status-color {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: $off-color;

                    &.active {
                        background-color: $active-color;
                    }
                }

                .origin-status-item-server {
                    @extend %text-no-overflow;
                    flex: 1;
                    padding: 0 10px;
                }

                .origin-status-item-legacy {
                    font-weight: normal;
                }
            }
        }
    }

    .protection-summary {
        background: var(--card-bg-color);
        border-radius: var(--card-border-radius);
        padding: 15px;
        font-weight: 500;
        font-size: 14px;
        color: var(--text-color);
        white-space: nowrap;

        .protection-tag-section {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        .protection-summary-title {
            font-weight: 500;
            font-size: 18px;
        }

        &.empty {
            display: flex;
            align-items: center;

            .protection-summary-title {
                margin-bottom: 0;
                flex: 1;
            }
        }

        .protection-summary-status {
            flex: 1;
            margin-right: 40px;
            &.out-of-plan {
                opacity: 0.7;
                color: $out-of-plan-color;
                font-weight: 400;
            }
        }

        .protection-summary-item {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: var(--app-summary-item-bg-color);
            border: 1px solid var(--card-separator-color);
            border-radius: 24px;
            padding: 5px 75px 5px 6px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .protection-summary-item-values {
                display: flex;
                align-items: center;
                flex: 1;

                .protection-summary-item-status-color {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .protection-summary-item-name,
                .protection-summary-item-status {
                    flex: 1;
                    margin-right: 10px;
                }

                &.active {
                    .protection-summary-item-status-color {
                        background-color: $active-color;
                    }

                    .protection-summary-item-status {
                        color: $active-color;
                    }
                }

                &.passive {
                    .protection-summary-item-status-color {
                        background-color: $passive-color;
                    }

                    .protection-summary-item-status {
                        color: $passive-text-color;
                    }
                }

                &.not-configured,
                &.disable {
                    .protection-summary-item-status-color {
                        background-color: var(--app-summary-item-status-disabled-bg-color);
                    }

                    .protection-summary-item-status {
                        color: var(--text-color);
                    }
                }

                &.out-of-plan {
                    opacity: 0.7;

                    .protection-summary-item-status-color {
                        background-color: var(--app-summary-item-status-not-available-bg-color);
                    }

                    .protection-summary-item-status {
                        color: $out-of-plan-color;
                        font-weight: 400;
                    }
                }

                .status-dimmer {
                    display: inline-block;
                    animation: flickerAnimation 2s infinite;
                }
            }

            .protection-summary-item-buttons {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 65px;
                height: 24px;
                margin: 3px 10px 3px 0;
            }
        }
    }
}
