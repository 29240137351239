.header-profile-menu {
    .rc-tooltip-inner {
        padding: 12px 0 !important; // make hover styles take whole tooltip width
    }

    .profile-menu-item {
        display: flex;
        align-items: center;
        height: 60px;
        width: 300px;
        padding: 0 30px;
        font-size: 16px;
        border-top: 0.5px solid transparent;
        border-bottom: 0.5px solid transparent;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom-color: var(--profile-menu-item-hover-border-color);
        }

        &:hover {
            background-color: var(--profile-menu-item-hover-bg-color);
            border-bottom-color: var(--profile-menu-item-hover-border-color);

            &:not(:last-child) {
                border-top-color: var(--profile-menu-item-hover-border-color);
            }
        }

        &:active,
        &:focus {
            background-color: var(--profile-menu-item-active-border-color);
        }

        &:hover,
        &:active,
        &:focus {
            .profile-menu-icon {
                background-color: var(--profile-menu-icon-hover-bg-color);
            }
        }

        .profile-menu-icon {
            margin-right: 20px;
            border-radius: 50%;

            &.MyProfile,
            &.SignOut {
                width: 30px;
                height: 30px;
            }

            &.MyProfile {
                background-image: var(--profile-menu-account-icon);
            }
            &.SignOut {
                background-image: var(--profile-menu-logout-icon);
            }
        }
    }
}

.app-header {
    .app-header-content {
        position: relative;
        z-index: 9999;

        display: flex;
        align-items: center;
        height: $global-header-height;
        background-color: var(--header-bg-color);
        padding-right: 40px;

        .logo {
            width: $closed-sidebar-width;
            height: 20px;
            margin-left: $app-left-gap;
            margin-right: $content-side-gap;
            object-fit: contain;
            vertical-align: middle;
            outline: none;
            cursor: pointer;
        }

        .brand {
            color: var(--text-color);
            font-size: 18px;
            font-weight: 500;
            margin-right: 30px;
            user-select: none;
        }

        .account-name {
            margin-top: 10px;
            font-size: 12px;
            line-height: normal;
            color: var(--dropdown-input-text-color);
        }

        .accounts-dropdown-wrapper {
            .accounts-dropdown {
                .account-option {
                    @extend %text-no-overflow;
                    line-height: normal;
                    &.ServiceProvider {
                        position: relative;
                        color: var(--dropdown-item-service-provider-text-color);
                        padding-left: 12px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            bottom: 50%;
                            left: 4px;
                            transform: translate(-50%, -50%);
                            width: 6px;
                            height: 6px;
                            background-color: var(--dropdown-item-service-provider-text-color);
                            border-radius: 50%;
                        }
                    }
                    &.Customer {
                        padding-left: 12px;
                    }
                }
            }
        }

        .top-header-viewers {
            display: flex;
            align-items: center;
            margin-left: auto;

            .header-viewer {
                display: inline-block;
                position: relative;
                align-self: center;

                &:not(:last-child) {
                    margin-right: 20px;
                }

                .alert-indicator {
                    width: 20px;
                    height: 20px;
                    background-size: contain;

                    &:hover,
                    &.active {
                        cursor: pointer;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 30px;
                            height: 30px;
                            background-color: var(--header-drawers-icons-active-bg-color);
                            border-radius: 50%;
                            z-index: -1;
                        }
                    }
                }
            }

            .activities-viewer-wrapper .alert-indicator {
                background-image: var(--header-activity-logs-icon);
                &.has-refine {
                    background-image: var(--header-activity-logs-pending-icon);
                }
            }

            .recommendations-viewer-wrapper {
                .alert-indicator {
                    background-image: var(--header-recommendations-icon);
                }

                .alerts-slider {
                    padding: 0;

                    .alerts-close {
                        position: absolute;
                        top: 17px;
                        right: 17px;
                    }
                }
            }

            .alerts-viewer-wrapper .alert-indicator {
                background-image: var(--header-security-alerts-icon);

                &.new-alert {
                    animation: new-alert 1.5s;
                    animation-iteration-count: 6;
                    transform-origin: top center;
                    background-image: url('../static-assets/images/bell-alert.svg');

                    &:hover,
                    &.active {
                        animation: none; // because animation is hidden underneath ':before'
                        &:before {
                            background-color: var(--header-security-alerts-active-bg-color);
                        }
                    }
                }

                @keyframes new-alert {
                    0% {
                        transform: rotate(0deg);
                        filter: drop-shadow(0px 0px 0px $new_yellow_200);
                    }
                    12.5% {
                        transform: rotate(-20deg);
                    }
                    25% {
                        transform: rotate(20deg);
                    }
                    37.5% {
                        transform: rotate(-15deg);
                    }
                    50% {
                        transform: rotate(15deg);
                        filter: drop-shadow(0px 0px 12px $new_yellow_200);
                    }
                    62.5% {
                        transform: rotate(-10deg);
                    }
                    75% {
                        transform: rotate(10deg);
                    }
                    87.5% {
                        transform: rotate(-5deg);
                    }
                    100% {
                        transform: rotate(0deg);
                        filter: drop-shadow(0px 0px 0px $new_yellow_200);
                    }
                }
            }

            .nav-item-open-search {
                background-color: transparent;
                a:after {
                    right: 19px !important;
                }
            }

            .try-new-portal {
                height: 35px;
                width: 238px;
                padding: 9px 10px 5px 42px;
                border-radius: 20px;
                background: #d0cfe0 url('../static-assets/images/back-curly.svg') no-repeat 14px center;
                color: #0e0ada;
                font-weight: 400;
                font-size: 14px;
                margin: 0 20px;
                cursor: pointer;
                box-shadow: 0 0 10px rgba(201, 201, 201, 0.2);
                &:hover {
                    box-shadow: 0 0 5px 2px #cfcfcf;
                }
            }
        }

        .header-profile-image-wrapper {
            padding: 0;
            background: none;
            border: none;

            .header-profile-image {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                white-space: normal; // for alt text

                &:hover {
                    box-shadow: var(--profile-menu-image-hover-bs);
                }
            }
        }
    }

    .info-list-wrapper {
        background-color: #dce2f4;

        .info-description {
            background-image: url('../static-assets/images/info-message_icon.svg');
            background-repeat: no-repeat;
            padding-left: 21px;
        }
    }

    .error-list-wrapper {
        background-color: #fbd6d9;

        .error-description {
            background-image: url('../static-assets/images/error-message_icon.svg');
            background-repeat: no-repeat;
            padding-left: 21px;
        }
    }

    .warning-list-wrapper {
        background-color: #ffe19f;

        .warning-description {
            background-image: url('../static-assets/images/warning-message_icon.svg');
            background-repeat: no-repeat;
            padding-left: 21px;
        }
    }

    .success-list-wrapper {
        background-color: #cce088;

        .success-description {
            background-image: url('../static-assets/images/ok-message_icon.svg');
            background-repeat: no-repeat;
            padding-left: 21px;
        }
    }

    .dropdown.disabled > div > a:after {
        display: none;
    }

    .locale-selector.navbar-right {
        margin: 7px 10px 0 auto;
        display: flex;
        .Select-control {
            font-size: unset;
            margin: 3px 0 0 10px;
            width: 120px;
            height: 32px;
            border: unset;
            padding: 0;
            .Select-value {
                display: inherit;
                @include control-font;
                line-height: 30px;
                color: var(--dropdown-input-text-color) !important;
            }
        }
        .Select-arrow {
            background-color: unset;
            background-size: 12px;
            top: 0;
            width: 20px;
            height: 32px;
            border: unset;
            position: absolute;
            right: 1px;
        }
        .Select.is-open .Select-arrow {
            background: url(../static-assets/images/arrow-select-blue.svg) no-repeat center;
            background-size: 12px;
            transform: rotate(180deg);
        }
        .Select.is-open > .Select-control .Select-arrow {
            top: 0px;
            border: unset;
        }
        .Select.is-open .Select-arrow,
        .Select .Select-arrow-zone:hover > .Select-arrow {
            border: unset;
        }
        .Select-control .Select-input:focus {
            border: unset;
        }
        .Select-option {
            font-size: unset;
            padding: 5px;
        }
        .Select-option.is-selected,
        .Select-option:hover {
            background-color: inherit;
            color: #448ab1 !important;
            &:before {
                content: '';
                width: 2px;
                height: 100%;
                background-color: #6499b7;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .Select-menu-outer {
            left: 10px;
            width: calc(100% - 9px);
        }
    }
}

.dropdown > div:first-child a {
    color: #f7fdff;
    font-size: 16px;
    font-weight: bold;
    border: none;
}

.success-list-content,
.warning-list-content,
.error-list-content,
.info-list-content {
    padding: 5px;
}

.header-date-filter-control {
    display: flex;
    align-items: center;
    h1 {
        @extend %page-header-title;
    }

    .dropdown > div:first-child a {
        padding: 5px;
        font-size: 12px;
        background-color: #f7fdff;
        color: #687077;
        border: 1px solid #cfd2d3;
        border-radius: 0;
    }
}

.header-date-filter-control-right {
    display: flex;
    margin-left: auto;
    align-items: center;

    .timer-wrapper {
        margin-right: 20px;
    }
}

.header-date-filter-settings-wrapper {
    position: relative;
    cursor: pointer;

    .header-date-filter-settings-header {
        display: flex;
        align-items: center;

        .information {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #6da7c8;

            .summary,
            .application,
            .connector,
            .date-time {
                font-size: 16px;
                line-height: 19px;
            }

            .summary,
            .application,
            .date-time {
                margin: 0 5px;
                font-weight: bold;
            }

            .connector {
                margin: 0 3px;
                font-weight: 400;
            }
        }

        .arrow {
            margin-left: 5px;
            width: 16px;
            height: 11px;
            border: none;
            outline: 0;

            &.down {
                background: url(../static-assets/images/arrow-down.png) center center;
                background-size: cover;
            }

            &.up {
                background: url(../static-assets/images/arrow-up.png) center center;
                background-size: cover;
            }
        }
    }

    .header-date-filter-settings {
        $header-date-filter-settings-padding: 20px;

        position: absolute;
        right: -12px;
        margin-top: 3px;
        z-index: 1000;
        width: 400px;
        background-color: var(--card-bg-color);
        box-shadow: $filters-box-shadow;
        border-radius: var(--card-border-radius);
        padding: 20px $header-date-filter-settings-padding 10px $header-date-filter-settings-padding;

        .time-date-control-wrapper {
            margin-top: 15px;
        }

        .datetime {
            align-items: flex-start;

            .arrow {
                height: 22px;
            }

            .datetime-from {
                .rdt .rdtPicker {
                    left: -$header-date-filter-settings-padding;
                }
            }

            .datetime-to {
                .rdt .rdtPicker {
                    right: 208px;
                }
            }

            .rdt {
                width: 170px;

                .rdtPicker {
                    position: relative !important;
                    width: 398px;
                    padding: 0 $header-date-filter-settings-padding;
                    background-color: inherit;
                    border: none;
                    box-shadow: none;

                    .rdtDays,
                    .rdtTime {
                        background-color: $white;
                    }
                    .rdtDay {
                        height: 30px;
                        border-radius: 15px;

                        &.rdtActive:before {
                            display: none;
                        }
                    }

                    .rdtTimeToggle {
                        height: 40px;
                    }
                }
            }
        }

        .Select-menu-outer {
            position: relative;
        }

        .validation-error {
            color: $error-color;
            font-size: 13px;
        }
    }

    .header-date-filter-settings-footer {
        display: flex;
        padding: 10px 0;

        button {
            border: none;
            width: 70px;
            height: 25px;
        }

        .clear {
            background-color: inherit;
            color: #6da7c8;
            width: 110px;
        }

        .discard {
            background-color: inherit;
            margin-left: auto;
            color: #6da7c8;
        }

        .apply {
            color: #f7fdff;
            background-color: #6da7c8;
            border: 1px solid #f7fdff;
            outline: 1px solid #6da7c8;
            margin-left: 10px;
        }
    }
}

.header-activity-log-container {
    .rc-tooltip-inner {
        padding: 6px 18px !important;

        .header-activity-log-tooltip-content {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .header-activity-log-refine-suggestion {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    background: #1daa66;
                    border-radius: 50%;
                    top: 10px;
                    left: -10px;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

/* Responsive */
@media (max-width: 767px) {
    .container-fluid > .navbar-header {
        min-height: $global-header-height;
    }

    .portal_userMgmt {
        margin-top: 0 !important;
        margin-bottom: 20px;
        float: left;
        clear: both;
        margin-left: -10px;
    }
}
