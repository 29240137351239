$paddingSides: 20px;
$icon_width: 6%;
$type_width: 10%;
$title_width: 16%;
$domain_width: 22%;
$date_width: 8%;
$region_width: 13%;
$events_width: 25%;

$show-parent-col_events_width: 15%;
$show-parent-col_parent_width: $events_width - $show-parent-col_events_width;

.new-items-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $page-padding;

    .card-items-container {
        flex-grow: 1;
        height: 0;
    }

    .card-items-container > .container,
    .container {
        height: 100%;
        width: 100%;
        margin-bottom: 0;
        padding: 0;
    }
}

.app-badge-wrapper {
    position: relative;
    width: $type_width;
    line-height: normal;

    .app-badge {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 4px 10px;
        min-height: 24px; // support thinner screens
        color: var(--badge-text-color);
        background-color: var(--badge-bg-color);
        font-size: 12px;
        line-height: normal;
        border-radius: 20px;

        .app-badge-icon {
            width: 16px;
            height: 16px;
        }

        &.IN_LINE {
            color: var(--application-type-in-line-color);
            background-color: var(--application-type-in-line-bg-color);
            .app-badge-icon {
                display: none;
            }
        }
        &.OUT_OF_PATH {
            color: var(--application-type-oop-color);
            background-color: var(--application-type-oop-bg-color);
            .app-badge-icon {
                background: var(--application-type-oop-icon) no-repeat center;
            }
        }
        &.BOT_ONLY {
            color: var(--application-type-bot-only-color);
            background-color: var(--application-type-bot-only-bg-color);
            .app-badge-icon {
                background: var(--application-type-bot-only-icon) no-repeat center;
            }
        }
        &.SNI {
            color: var(--application-type-sni-color);
            background-color: var(--application-type-sni-bg-color);
            .app-badge-icon {
                display: none;
            }
        }
        &.HSM {
            // NoKey
            color: var(--application-type-no-key-color);
            background-color: var(--application-type-no-key-bg-color);
            .app-badge-icon {
                background: var(--application-type-no-key-icon) no-repeat center;
            }
        }
    }

    .cwaf-bypass-badge {
        font-weight: 600;
        color: $new_white;
        background-color: $red-200;
    }
}

.new-app-items-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--table-bg-color);
    border-radius: var(--table-border-radius);
    padding-left: var(--table-side-gap);
    padding-right: var(--table-side-gap);
    overflow: hidden;

    .grid-items-item > div {
        display: flex;
    }

    .grid-items-item {
        min-height: 0 !important;
        padding: 0;
    }

    .item-list-item.new-app-item {
        @include body-4-font;
        display: flex !important;
        flex-direction: row !important;
        min-height: 0 !important;
        color: var(--text-color);
    }

    .item-list-item.new-app-item > div,
    .item-list-item.new-app-item .fields > li {
        padding: 0 15px;
        line-height: 1.7;
    }

    .grid-items-item .item-list-item {
        padding: 0 20px;
        line-height: 1.5;
        &:before {
            background-image: none !important;
        }
    }

    .icon {
        width: $icon_width;
        .provisioning {
            background: url(../static-assets/images/provisioning-icon.svg) no-repeat center;
            width: 46px;
            height: 33px;
        }

        .protecting {
            background: url(../static-assets/images/protecting-icon.svg) no-repeat center;
            width: 46px;
            height: 33px;
        }

        .learning {
            background: url(../static-assets/images/learning-icon.svg) no-repeat center;
            width: 47px;
            height: 32px;
        }

        .deleted {
            background: url(../static-assets/images/deleted-app-icon.svg) no-repeat center;
            width: 42px;
            height: 34px;
        }
    }

    .title {
        width: $title_width;
    }

    .domain {
        width: $domain_width;
        display: flex;
        align-items: center;

        .domain-value {
            flex: 1;
            width: 0;
        }

        .cwaf-bypass-badge-wrapper {
            width: auto;
            margin-left: 10px;
        }

        .health-monitoring {
            display: flex;
            align-items: center;
            margin-left: 10px;

            .application-origin-servers {
                margin-left: 20px;
            }
        }
    }

    .date {
        width: $date_width;
    }

    .region {
        width: $region_width;
    }

    .parent,
    .region {
        @extend %text-no-overflow;
    }

    .events {
        width: $events_width;
        display: flex;
        .card-item-bottom .chart {
            right: 25px;
            bottom: 0;
            top: 0;
            height: 100%;
            left: auto;
            .chart-content-wrapper {
                height: 100%;
                bottom: 0;
            }
        }

        .events_number {
            width: 85px;
            @extend %text-no-overflow;
        }
    }

    .show-parent-col {
        .events {
            width: $show-parent-col_events_width;
        }
        .parent {
            width: $show-parent-col_parent_width;
        }
    }

    .custom_tag {
        right: auto;
        left: 32px;
        bottom: 4px;
        line-height: 1.5;
        top: auto;
    }

    .security-hardening {
        position: absolute;
        left: -35px;
    }

    .list-headers {
        display: flex;
        list-style: none;
        padding: 15px 70px 15px 20px;
        border-bottom: 1px solid var(--table-row-border-color);

        &.show-parent-col .header-field {
            &.EVENTS {
                width: $show-parent-col_events_width;
            }
            &.PARENT {
                width: $show-parent-col_parent_width;
            }
        }

        .header-field {
            padding: 0 15px;

            &.STAGE {
                width: $icon_width;
            }
            &.TYPE {
                width: $type_width;
            }
            &.NAME {
                width: $title_width;
            }
            &.DOMAIN {
                width: $domain_width;
            }
            &.DATE {
                width: $date_width;
            }
            &.REGION {
                width: $region_width;
            }
            &.EVENTS {
                width: $events_width;
            }

            @include sort-btns();
        }
    }

    .chart_container {
        width: 100%;
        .chart {
            position: relative;
            .chart-content-wrapper,
            .trend-chart-container {
                position: absolute;
                height: $list-large-item-height;
                top: -23px;
                width: 100%;
            }
        }
    }

    .grid-items-item .item-list-item {
        align-items: center;
    }

    .ellipsis-icon {
        width: auto;
        height: auto;
        position: relative;
        padding: 0 !important;
        cursor: default;
        top: auto;
    }

    .card-item-options {
        padding: 7px 5px;
        box-shadow: none;
        right: -37px;
        .edit_icon {
            margin-bottom: 5px;
        }
    }
}

.app-content .appsContainer {
    &.card-items-container .container {
        position: initial;
    }
    .activity-list {
        flex-grow: 1;
        height: 0;
    }
}

.app-content .appsContainer,
.users__list__container {
    .card-item-options {
        padding: 7px 5px;
        -webkit-box-shadow: none;
        box-shadow: none;
        right: auto;
        position: relative;
        opacity: 1;
        visibility: visible;
        width: 32px;

        .edit_icon {
            margin-bottom: 5px;
        }
    }
    .list-headers {
        width: 100%;
        height: 50px;
    }
}

.application-operator-main-domain-input {
    max-width: 350px;

    input {
        width: 350px;
    }
}

.generalInfoContainer {
    .single-line {
        display: flex;
        div {
            flex: 1;
        }
        div:first-child {
            margin-right: 10px;
        }
    }
    input,
    textarea {
        width: calc(100% - 175px);
        resize: none;
    }
    textarea {
        vertical-align: top;
        height: 70px;
    }
    .input_container {
        margin-bottom: 15px;
        float: left;
        width: 100%;
        label,
        textarea,
        input {
            display: inline-block;
        }
        label {
            width: 175px;
        }
    }
}

.gen-list-item-input,
.gen-list-headers-regular,
.gen-list-headers-sortable {
    &.app-rule-field-enabled {
        flex: 1;
    }

    &.app-rule-field-name {
        flex: 2;
    }

    &.app-rule-field-summary,
    &.app-rule-field-description {
        flex: 3;
    }
}

.new-design .gen-list-mgmt {
    //$andColor: #00c349;
    //$orColor: #00c0ef;
    .form-switch-btn-wrapper.toggle-with-text {
        .form-switch-btn.off:before {
            content: 'All';
        }

        .form-switch-btn.on:before {
            content: 'Any';
        }

        .form-switch-btn.on .form-switch-btn-state {
            left: 40px;
        }

        .form-switch-btn {
            min-width: 65px;
        }
    }

    .app-rule-field-summary {
        .invalid-input {
            margin: 0 10px;
            align-self: unset;
        }
    }

    span.input-label {
        margin: 16px 10px 10px 0;
    }

    .input-label,
    .input-label > span {
        font-size: 13px;
        align-self: flex-start;
    }

    .checkbox-wrapper {
        .input-label,
        .input-label > span {
            align-self: unset;
        }
    }

    .gen-list-item-edit-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .gen-list-headers-regular,
        .gen-list-item-input {
            width: calc((100% - 300px) / 2);

            input,
            textarea {
                max-width: calc(100% - 10px);
                padding-right: 4px;
            }
        }

        .gen-list-item-edit-part {
            width: 100%;
            display: flex;

            &.details {
                margin-bottom: 10px;

                .checkbox-wrapper.checkbox-with-label {
                    .input-label,
                    .input-label > span {
                        align-self: flex-start;
                    }
                }
            }

            &.multi-fields-container {
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            .fields-container {
                display: flex;
                flex-wrap: wrap;
            }
            .condition-fields {
                display: flex;
                flex-wrap: wrap;
                > div {
                    margin: 10px 10px 0 0;
                }
            }
            .fields-container.action-fields > div {
                align-self: flex-start;
            }
            .fields-container > div {
                &.checkbox {
                    align-self: center;
                }
                margin: 10px 10px 0 0;
                input[type='checkbox'] + label {
                    width: max-content;
                    max-width: 200px;
                    align-self: center;
                    padding-left: unset;
                }

                input {
                    //height: 36px;
                }

                input,
                textarea {
                    padding-right: 4px;
                }

                input[type='radio'] + label {
                    margin: 5px 10px 0 0;
                }
                &.radio-options {
                    display: flex;
                }

                &.checkbox-wrapper {
                    margin-top: 28px;
                }
            }
            .fields-container .redirect-field {
                input[type='checkbox'] + label {
                    padding-left: 15px;
                    margin-top: 9px;
                    margin-left: 6px;
                    position: relative;
                    text-transform: none;
                }
            }
        }

        .gen-list-item-input {
            align-self: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }

        .checkbox-wrapper.checkbox-with-label {
            flex-direction: row;

            input[type='checkbox'] {
                padding: 0;
            }
        }
    }
}

.app-rules .gen-list-item-edit-part {
    &.details {
        margin-bottom: 10px;
        .app-rule-field-name {
            padding-left: 28px;
        }
        .app-rule-field-description {
            flex: 6;
            textarea {
                width: 400px;
                height: 60px;
            }
        }
    }
    .app-rule-field-andOr {
        display: flex;
    }
    .condition-type .Select,
    .condition-field-operator .Select {
        min-width: unset;
        width: 180px;
    }
    .condition-field-methods input,
    .condition-field-methods .Select,
    .condition-field-countries .Select {
        width: 350px;
    }
    .condition-field-ips input,
    .condition-field-ips .Select {
        width: 400px;
    }
    .condition-field-ips .Select .Select-clear-zone {
        right: 0;
    }
    .action-type {
        width: 200px;
        .Select {
            min-width: unset;
            width: 200px;
        }
    }
}
.gen-list-mgmt.endpoints {
    .fields-container .Select {
        min-width: unset;
        width: 150px;
    }
    .fields-container input {
        width: 100px;
    }
    .fields-container .quota-threshold input {
        width: 60px;
    }

    .gen-list-item-edit-container .gen-list-item-edit-part .fields-container .radio-options {
        margin-right: 0;
    }
    .gen-list-headers.tiny-buttons {
        padding: 5px 20px;
        margin-right: 60px;
    }

    .input-label.quota-label {
        margin-left: 10px;
    }
    .quota-unlimited label,
    .input-label.quota-transactions {
        font-weight: 400;
    }
    .quota-limited {
        margin-left: 20px;
    }
}

.endpoints.gen-form {
    .endpoints-status-dropdown {
        width: 200px;
    }

    .endpoints-filter-dropdown {
        width: 250px;
        margin-left: 20px;
    }

    .endpoints-list {
        max-height: 500px;
        margin-top: 10px;

        .checkbox-column {
            max-width: 70px;

            .checkbox-wrapper {
                width: 20px;
                height: 20px;
            }
        }

        .endpoint-column {
            min-width: 260px;
        }

        .status-column {
            min-width: 230px;
        }

        .origin-column-value {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
        }

        .endpoint-methods-list {
            margin: 10px 0;

            .small-column {
                max-width: 300px;
                min-width: 300px;
            }

            input[type='radio'] + label {
                margin: 0;
            }

            .endpoint-methods-list-container {
                background-color: var(--table-expanded-row-bg-color);

                .endpoint-methods-list-headers {
                    border-bottom: 1px solid var(--table-row-border-color);
                }
            }

            .endpoint-method-item {
                .quota-fields {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    row-gap: 5px;

                    .quota-unlimited {
                        margin-right: 30px;
                    }

                    .limit-quota-field {
                        display: flex;
                        align-items: center;

                        & > *:not(:last-child) {
                            margin-right: 10px;
                        }

                        .quota-transactions-label {
                            align-self: center;
                        }

                        .quota-threshold input {
                            width: 60px;
                            padding-right: 8px;
                        }
                    }

                    .Select {
                        min-width: unset;
                        width: 150px;
                    }

                    input {
                        width: 100px;
                    }
                }

                .parameters-list {
                    margin: 10px 0;

                    .parameter-fields {
                        max-width: 550px;
                        margin: 10px 0;

                        & > *:not(:last-child) {
                            margin-bottom: 10px;
                        }

                        .parameter-style-field {
                            display: inline-block;
                        }

                        .json-schema-radio-group {
                            display: flex;
                            margin-bottom: 5px;

                            .schema-radio-field {
                                margin-right: 20px;
                            }
                        }

                        .parameter-description-field {
                            input {
                                width: 100%;
                            }
                        }

                        .parameter-checkboxes-wrapper {
                            display: flex;

                            .query-parameter-checkboxes {
                                display: flex;
                            }

                            .parameter-checkbox {
                                margin-right: 10px;
                            }
                        }
                    }
                }

                .base-paths-list {
                    margin: 10px 0;

                    .base-path-item {
                        align-items: center;
                    }
                }
            }
        }
    }
}

.new-design .accordion-element {
    margin-bottom: 20px;

    &.api-protection-accordion {
        .api-protection-controls {
            display: flex;
            align-items: center;
            .dropdown {
                width: 200px;
            }

            .api-discovery-button-wrapper {
                display: flex;
                align-items: center;
                margin-right: 10px;

                .api-discovery-button {
                    margin: 0;
                    pointer-events: initial;
                }

                .information-tooltip-icon-container .information-tooltip-icon {
                    position: initial;
                    height: 15px;
                    width: 15px;
                    margin-left: 5px;
                }
            }

            .outlined-button {
                margin: 0 15px 0 0;
                input[type='file'] {
                    display: none;
                }
                label {
                    color: unset;
                    cursor: pointer;
                    &[disabled] {
                        cursor: not-allowed;
                    }
                }
            }
        }

        .download-api-file {
            display: flex;
            align-items: center;
            width: 48px;
            .sign-in-spinner {
                position: relative;
                top: 18px;
                height: 36px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &.bla-dashboard-accordion {
            .Collapsible__contentInner {
                padding-top: 0;
            }
        }
        .new-badge {
            padding: 3px 8px;
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
            border-radius: 4px;
            background-color: var(--new-badge-bg-color);
            color: var(--new-badge-color);
        }
    }

    .merge-policy-form {
        .merge-policy-section {
            padding: 0 16px;

            .merge-policy-label {
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: var(--text-color);

                .information-tooltip-icon {
                    width: 13px;
                    height: 13px;
                }
            }

            .merge-policy-inputs-container {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 19px;

                .merge-policy-dropdown {
                    width: 280px;
                    margin-right: 10px;
                }
            }
        }

        .line-separator {
            width: 100%;
            height: 1px;
            margin-top: 20px;
            margin-bottom: 18px;
            background-color: rgba(197, 199, 208, 0.4);
        }
    }
    &.bla-accordion {
        .accordion-title {
            display: flex;
            align-items: center;
            gap: 8px;
            .title span {
                display: inline-flex;
                padding-top: 2px;
                line-height: 16px;
            }
        }
    }

    .bla-form {
        .bla-fields-main-container {
            gap: 30px;
            display: flex;
            flex-direction: column;
        }
        .bla-fields-container {
            display: flex;
            gap: 20px;
            padding-left: 6px;
        }
        .bla-field {
            &-tooltip {
                max-width: 280px;
                p {
                    margin: 0;
                }
            }

            &-label {
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: var(--text-color);
                margin-bottom: 6px;
                padding: 0 10px;
                .help-tooltip {
                    display: flex;
                }
                .information-tooltip-icon {
                    width: 13px;
                    height: 13px;
                }
            }
            &-dropdown {
                width: 290px;
                .Select-option.is-disabled {
                    pointer-events: none;
                }
            }
        }
    }

    .bla-dashboard {
        &-tabs {
            margin-bottom: 15px;
        }

        &-indicator {
            background-color: var(--bla-dashboard-card-bg-color);
            border-radius: 20px;
            padding: 14px;

            .bla-section {
                &-header {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 20px;
                    &-title {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                    }
                    .badge {
                        border-radius: 4px;
                        background-color: var(--bla-dashboard-badge-bg-color);
                        color: var(--text-secondary-color);
                        padding: 5px 10px;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14px;
                        .value {
                            color: var(--bla-dashboard-badge-text-color);
                            font-weight: 500;
                        }
                    }
                }
                &-items-wrapper {
                    display: flex;
                }
                .item {
                    width: 25%;
                    display: flex;
                    gap: 10px;
                    &-icon {
                        width: 30px;
                        height: 30px;
                        border-radius: 4px;
                        background-color: var(--bla-dashboard-item-icon-bg-color);
                        background-origin: border-box;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    .cycle-duration-icon {
                        background-color: var(--bla-dashboard-badge-bg-color);
                        background-image: var(--clock2-icon);
                    }
                    .added-rules-icon {
                        background-image: var(--book-icon);
                    }
                    .deleted-rules-icon {
                        background-image: var(--cancel-icon);
                    }
                    .tx-received-icon {
                        background-image: var(--sync-alt-icon);
                    }
                    .actor-seen-icon {
                        background-image: var(--actor-card-icon);
                    }
                    .actor-blocked-icon {
                        background-image: var(--actor-blocked-icon);
                    }
                    .sessions-seen-icon {
                        background-image: var(--sessions-seen-icon);
                    }
                    &-summary {
                        font-size: 12px;
                        line-height: 14px;
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                    }
                    &-value {
                        font-weight: 500;
                    }
                    &.cycle-duration {
                        .item-value {
                            color: var(--bla-dashboard-badge-text-color);
                        }
                    }
                }
                &-divider {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    border-bottom: 1px solid var(--bla-dashboard-divider-color);
                }
            }
        }

        &-active-rules {
            .protection-rules {
                .search-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
                .protection-rules-filter-dropdown {
                    width: 250px;
                }

                .protection-rules-header {
                    align-items: flex-end;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    .right {
                        display: flex;
                        align-items: flex-end;
                        gap: 20px;
                        .export-icon {
                            margin-left: 0;
                        }
                    }
                }

                .sort-header {
                    padding-right: 15px;
                    border-bottom: 1px solid var(--table-row-border-color);
                    .property {
                        white-space: nowrap;
                        font-size: 14px;
                        &:not(.sortable) {
                            cursor: auto;
                        }
                        &.status {
                            flex-basis: 100px;
                        }
                    }
                }
                .rule {
                    min-height: 50px;
                }

                .property {
                    line-height: 20px;
                    .property-value {
                        font-size: 12px;
                    }
                    &.status {
                        flex: 0 0 100px;
                    }
                    &.ruleType {
                        flex: 0 0 120px;
                    }
                    &.moreInfo {
                        flex: 0 0 120px;
                    }
                    &.confidenceScore {
                        flex: 0 0 160px;
                    }
                    &.actorSeen {
                        flex: 0 0 120px;
                    }
                    &.transactionsReceived {
                        flex: 0 0 180px;
                    }
                }
            }
        }
    }

    .bla-field-sensitivity-dropdown {
        .Select {
            min-width: 200px;
        }
    }

    .jwt-claim {
        input {
            width: 290px;
        }
    }

    .bla-exclude-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 16px;
        .left {
            display: flex;
            align-items: center;
            gap: 20px;
            .title {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }

    .bla-exclude-list {
        padding-right: 0px;
        font-size: 14px;
        line-height: 16px;
        margin-top: 45px;
        margin-bottom: 20px;
        .sort-header {
            border-bottom: 1px solid var(--table-row-border-color);
            .property {
                font-size: 14px;
                line-height: 16px;
            }
        }
        .rule-list-wrapper {
            max-height: 'initial';
            overflow: visible;
        }
        .list-item-indication {
            left: 0;
        }
        .gen-list-item {
            .Select {
                width: 200px;
            }
            .property-value {
                font-size: 12px;
                line-height: 14px;
                color: var(--text-secondary-color);
            }
        }
        .gen-list-item-input {
            > div {
                width: 100%;
                max-width: 200px;
            }
        }
        .gen-list-item-buttons {
            width: 90px;
            justify-content: flex-end;
        }
    }
}

.application-origin-servers {
    .application-origin-servers-badge {
        &.all-origins-online {
            background-color: $active-color;
        }

        &.all-origins-down {
            background-color: $off-color;
        }

        &.some-origins-down {
            background-color: $passive-color;
        }
    }
}

.diversion-status {
    .diversion-status-badge {
        &.ARECORD,
        &.BOTH {
            background-color: $active-color;
        }

        &.CNAME {
            background-color: $disable-color;
        }
    }
}

.badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px 11px;
    font-weight: 600;
    font-size: 10px;
    color: $white;
    white-space: nowrap;
}

.new-design .app-sp-badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    height: 30px;
    gap: 6px;
    padding: 3px 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    border-radius: 4px;
    color: var(--app-sp-badge-color);
    background-color: var(--app-sp-badge-bg-color);

    .app-sp-badge-icon {
        width: 12px;
        height: 12px;
        background-image: url(../static-assets/images/i_tooltip_icon_hover.svg);
    }
}

.geo-blocking {
    .gen-list-mgmt {
        margin-top: 10px;
        .gen-list-item-input {
            input {
                width: 100%;
                max-width: $input-long-width;
            }
            textarea {
                width: 100%;
                max-width: 700px;
            }
            .invalid-input {
                width: $input-long-width;
                align-self: flex-start;
                text-align: end;
            }
        }
    }

    .template-fields .template-inherited {
        margin-right: 20px;
    }

    .policy-radio-group {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        margin-bottom: 20px;

        .custom-policy-radio {
            margin-right: 40px;
        }

        .shared-policy-radio,
        .custom-policy-radio {
            input[type='radio'] + label {
                line-height: inherit;
            }
        }

        .shared-policy {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            .shared-policy-radio {
                margin-right: 10px;
            }
            .shared-policy-select {
                width: 315px;
                z-index: 2; // make it visible above the dual list (countries)
            }
        }
    }

    .dual-list.disabled {
        pointer-events: auto;
        .Select {
            cursor: not-allowed;
        }
    }

    .custom-policy .dual-list {
        .Select .Select-menu-outer {
            z-index: auto;
        }
    }
}

.bot-wl {
    .gen-list-item-input {
        .bot-wl-ip-input,
        .bot-wl-description-input {
            width: 320px;
            &.is-invalid-input {
                padding-right: 30px;
            }
        }
        span.validation-error {
            width: 100%;
        }
    }
}

.toggle-with-tooltip-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .information-tooltip-icon-container .information-tooltip-icon {
        position: initial;
        height: 15px;
        width: 15px;
        margin-right: 5px;
    }
}

.section-operator-controls-wrapper {
    display: flex;
    width: 1360px;

    .section-operator-controls {
        &:not(:last-child) {
            margin-right: 40px;
        }
    }
}

.section-operator-controls {
    display: flex;
    align-items: center;
    margin: 20px 0;
    .accordion-category-header-name {
        margin-right: 20px;
        color: var(--text-color);
    }
    .form-content-wrapper {
        margin: 0 0 0 70px;
        display: flex;
        &:first-child {
            margin-left: 0;
        }
        .field-wrapper {
            display: flex;
            label {
                width: 100%;
                padding: 4px 10px 0 0;
            }
            input {
                width: 80px;
                height: 30px;
            }
        }
        .generic-form-options {
            width: unset;
            margin: 0;
            button {
                width: 90px;
                line-height: unset;
                padding: 5px 10px;
                font-size: 13px;
            }
        }
    }
}

.external-link-wrapper {
    .external-link {
        display: inline-flex;
        align-items: center;

        .external-link-label {
            margin-right: 10px;
            color: #6499b7;
            font-size: 16px;
            font-weight: 500;
        }

        .external-link-icon {
            background: url(../static-assets/images/external-link.svg) no-repeat center;
            background-size: 19px;
            width: 19px;
            height: 19px;
        }
    }
}

.accordion-wrapper .section-operator-controls {
    margin: 0;
    padding: 0px 0 5px 0;
    width: 100%;
    .accordion-category-header-name {
        font-size: 14px;
    }
}

.cdn-behaviors .gen-list-container {
    .pathPattern {
        flex-grow: 2;
    }
    .cachePolicy {
        flex-grow: 2.5;
        margin-right: 15px;

        > span {
            @extend %text-no-overflow;
        }

        .select-with-validation {
            width: 100%;
            max-width: 400px;
            .Select {
                min-width: unset;
                width: inherit;
                .Select-value-label {
                    display: inline-block;
                    max-width: calc(100% - 30px);
                    @extend %text-no-overflow;
                }
            }
        }
    }
}

.cdn-invalidate {
    .gen-list-container {
        .gen-list-headers {
            padding-right: 50px;
        }
        .gen-list-item {
            padding-top: 14px;
            transition: box-shadow 0.15s, z-index 0.15s;

            &.is-extended {
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2);
                z-index: 1;
            }

            .gen-list-item-buttons {
                width: 30px;
                margin-top: 0;
            }

            .cdn-invalidate-extended {
                margin: 16px 10px 12px 0;
                padding-top: 8px;
                border-top: 1px solid $gray-700;

                .gen-list-item-input {
                    flex-wrap: wrap;
                    align-items: baseline;

                    label {
                        margin-right: 8px;
                        font-size: inherit;
                    }

                    .object-path {
                        margin: 8px 8px 0 0;
                        padding: 1px 8px;
                        background-color: #bddfe9;
                        border-radius: 16px;
                    }
                }
            }

            .invalidation-status {
                &.completed {
                    color: $green-200;
                }
                &.failed {
                    color: $red-200;
                }
            }
        }

        .tracking-id {
            flex-grow: 2;
        }
    }
}

.cdn-invalidate-form {
    @extend %global-modal-buttons;
    @extend %buttons-container;

    .object-paths {
        textarea {
            width: 100%;
            min-height: 150px;
            max-height: 500px;
            resize: vertical;
        }
        &.invalid-input textarea {
            outline: 2px solid $red-200;
        }
    }
}

.oop-plugin-download {
    .oop-plugin-download-controls {
        display: flex;

        .select-field-wrapper {
            width: 250px;
            margin-right: 20px;
        }
    }
}

.cwaf-bypass-modal-title {
    display: flex;
    gap: 8px;
    align-items: center;
    &.error-title {
        color: $error-color;
    }

    .cwaf-bypass-modal-icon {
        width: 20px;
        height: 18px;
        background: url('../static-assets/images/error-icon.svg') no-repeat center center;
        background-size: contain;
    }
}
.cwaf-bypass-modal-body .cwaf-bypass-modal-body-note {
    color: $error-color;
}

.beta-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 39px;
    height: 20px;
    padding: 3px 7px;
    background: #e0d5ff;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $violet;
}

.pci-icon {
    width: 30px;
    height: 30px;
    background: url('../static-assets/images/pci.svg') no-repeat center center;
}

.beta-feature-title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}
.api-protection-page-wrapper-tabs {
    height: auto;
    margin-bottom: 30px;
}
.upload-SDL-btn {
    margin-left: 8px;
    .outlined-button {
        height: 35px;
        font-size: 18px;
        font-weight: 400;
    }
}
.graph-ql-section {
    .graphQL-section-body {
        margin-left: 15px;
        .input-label {
            margin-bottom: 1px;
            > span {
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                padding-left: 5px;
            }
        }
        .invalid-input {
            float: initial;
            padding-left: 10px;
        }
    }
    .form-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .text-container input {
            width: 100px;
        }
    }
}

.bypass-tag {
    padding: 5px 8px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    line-height: initial;
    height: 24px;
}
.bypass-tag-fully-bypass {
    color: var(--bot-bypass-fully-text-color);
    background-color: var(--bot-bypass-fully-bg-color);
}
.bypass-tag-partially-bypass {
    color: var(--bot-bypass-partially-text-color);
    background-color: var(--bot-bypass-partially-bg-color);
}
