:root,
.singlemode {
    --font-family: 'Roboto', Verdana;

    /* Components Colors */
    --text-color: #264769;
    --text-secondary-color: #6b7280;
    --page-bg-color: #{$new_gray_100};

    /** Tooltip **/
    --tooltip-bg-color: #{$new_white};
    --tooltip-text-color: #6b7280;
    --tooltip-border-color: #c0cddf;
    --tooltip-button-hover-bg-color: #e6e9ef;

    /** Profile Menu **/
    --profile-menu-image-hover-bs: 0px 0px 6px 1px #afb4b8;
    --profile-menu-item-hover-bg-color: #f7f8f9;
    --profile-menu-item-hover-border-color: #e9f1f3;
    --profile-menu-item-active-border-color: rgba(236, 242, 248, 0.6);
    --profile-menu-account-icon: url(../static-assets/images/account2.svg);
    --profile-menu-logout-icon: url(../static-assets/images/exit.svg);
    --profile-menu-icon-hover-bg-color: var(--generic-icon-hover-bg-color);

    /** Dropdown **/
    --dropdown-border-color: rgba(46, 88, 148, 0.3);
    --dropdown-hover-border-color: rgba(53, 92, 213, 0.7);
    --dropdown-active-border-color: #{$new_blue_900};
    --dropdown-input-text-color: #6b7280;
    --dropdown-list-bg-color: #{$new_white};
    --dropdown-list-box-shadow: 0px 1px 4px rgba(37, 49, 53, 0.15);
    --dropdown-list-border-color: #ecf2f8;
    --dropdown-item-hover-bg-color: rgba(225, 227, 230, 0.25);
    --dropdown-item-active-bg-color: rgba(236, 242, 248, 0.6);
    --dropdown-item-border-color: #e9f1f3;
    --dropdown-item-text-color: #6b7280;
    --dropdown-item-service-provider-text-color: #{$new_blue_400};
    --dropdown-disabled-bg-color: rgba(243, 242, 242, 0.5);
    --dropdown-disabled-text-color: rgba(107, 114, 128, 0.8);
    --dropdown-primary-arrow-icon: url(../static-assets/images/new-design/chevron.svg);
    --dropdown-hover-arrow-icon: url(../static-assets/images/new-design/chevron.svg);
    --dropdown-selected-arrow-icon: url(../static-assets/images/new-design/chevron-selected.svg);
    --dropdown-disabled-arrow-icon: url(../static-assets/images/new-design/chevron-disabled.svg);
    --dropdown-scroller-color: #d3d5d9;
    --dropdown-label-color: #264769;
    --dropdown-placeholder-color: rgba(107, 114, 128, 0.7);
    --dropdown-disabled-label-color: rgba(38, 71, 105, 0.8);
    --dropdown-toggle-bg-color: #e6e6e6;
    --dropdown-toggle-hamburger-slice-bg-color: #8c8c8c;

    /* Checkbox */
    --checkbox-border-disabled-color: #e6e6eb;
    --checkbox-selected-color: #264769;
    --checkbox-label-color: #264769;

    /* Control */
    --required-control-asterisk-color: #ff0606;
    --control-border-color: rgba(46, 88, 148, 0.3);
    --control-hover-border-color: rgba(53, 92, 213, 0.7);
    --control-selected-border-color: #355cd5;
    --control-text-color: #6b7280;
    --control-placeholder-color: rgba(107, 114, 128, 0.7);
    --control-disabled-bg-color: rgba(243, 242, 242, 0.5);
    --control-disabled-text-color: rgba(107, 114, 128, 0.5);

    /* Search */
    --search-primary-border-color: rgba(46, 88, 148, 0.3);
    --search-hover-border-color: rgba(53, 92, 213, 0.7);
    --search-active-border-color: #{$new_blue_900};
    --search-icon: url(../static-assets/images/new-design/search-icon-small.svg);
    --search-close-icon: url(../static-assets/images/new-design/close-icon-small.svg);
    --search-input-text-color: #6b7280;
    --search-input-placeholder-color: #6b7280;

    /* Table */
    --table-side-gap: 16px; // for tables not in a section
    --table-bg-color: #{$new_white};
    --table-border-radius: 20px;
    --table-row-border-color: #{$new_gray_200};
    --table-row-hover-bg-color: rgba(225, 227, 230, 0.25); // #e1e3e6
    --table-row-active-bg-color: rgba(236, 242, 248, 0.6); // #ecf2f8
    --table-expanded-row-bg-color: #f7f8f9;
    --table-header-column-text-color: #264769;
    --table-data-column-text-color: #6b7280;

    /* Section */
    --section-bg-color: #{$new_white};
    --section-border-color: #c5c7d0;
    --section-border-radius: 20px;
    --section-header-hover-bg-color: #fdfdfd;
    --section-header-active-bg-color: var(--section-bg-color);
    --section-header-desc-text-color: #6b7280;
    --section-icon-hover-bg-color: var(--generic-icon-hover-bg-color);

    /* Card */
    --card-bg-color: #{$new_white};
    --card-border-radius: 20px;
    --card-separator-color: #{$new_gray_200};
    --card-loader-color: #{$new_blue_200};
    --card-hover-bg-color: #fdfdfd;
    --card-inner-bg-color: var(--card-bg-color);
    --card-summary-bg-color: #{$new_gray_300};
    --card-header-desc-text-color: #6b7280;
    --card-icon-hover-bg-color: var(--generic-icon-hover-bg-color);
    --card-box-shadow: 0 0 2px #bdbdbd;

    /** Header **/
    --header-bg-color: #{$new_gray_100};
    --header-recommendations-icon: url('../static-assets/images/bulb.svg');
    --header-activity-logs-icon: url('../static-assets/images/logs.svg');
    --header-activity-logs-pending-icon: url('../static-assets/images/logs-pending.svg');
    --header-security-alerts-icon: url('../static-assets/images/bell.svg');
    --header-drawers-icons-active-bg-color: #cbd4db;
    --header-security-alerts-active-bg-color: #{$new_yellow_300};

    /** Sidebar **/
    --sidebar-bg-color: #{$new_blue_400};
    --sidebar-shadow-color: #{rgba($new_black, 0.32)};
    --sidebar-item-bg-color: #2a4561;
    --sidebar-item-hover-bg-color: #6f849b;
    --sidebar-tooltip-item-hover-bg-color: #5f7890;
    --sidebar-tooltip-bg-color: #28496a;
    --sidebar-item-active-indicator-bg-color: #{$new_red_100};
    --toggle-sidebar-btn-bs: 0px 0px 6px 2px rgb(200, 8, 8, 0.3);

    /** Footer **/
    --footer-bg-color: #{$new_gray_100};
    --footer-text-color: #{$new_dark_gray_900};
    --footer-bolded-text-color: #{$new_white};
    --footer-bolded-text-bg-color: #{$new_blue_400};
    --footer-bolded-text-hover-bs-color: #2e6dae;

    /** Tabs **/
    --tab-active-line: #264769;
    --tab-active-text-color: #0e0ada;
    --tab-active-bg-color: #3c4144;

    /* Icons */
    --close-icon: url(../static-assets/images/close-dark.svg);
    --certificate-icon: url(../static-assets/images/new-design/certificate.svg);
    --certificate-disabled-icon: url(../static-assets/images/new-design/certificate-disabled.svg);
    --add-button-bg-color-disabled: #{$add-icon-disabled-bg-color};
    --add-button-box-shdow-hover: 0px 0px 5px 2px rgba(53, 59, 210, 0.35);
    --plus-icon-disabled: url(../static-assets/images/new-design/disabled-plus-icon.svg);
    --discard-delete-icon-primary: url(../static-assets/images/new-design/delete-restore-primary.svg);
    --discard-delete-icon-hover: url(../static-assets/images/new-design/delete-restore-hover.svg);

    --generic-icon-hover-bg-color: #{$new_gray_1000};

    --csv-icon-primary: url(../static-assets/images/new-design/csv-export-primary.svg);
    --csv-icon-hover: url(../static-assets/images/new-design/csv-export-hover.svg);
    --download-icon-primary: url(../static-assets/images/new-design/download-primary.svg);
    --download-icon-hover: url(../static-assets/images/new-design/download-hover.svg);
    --download-icon-new-primary: url(../static-assets/images/new-design/download-new.svg);
    --download-icon-new-hover: url(../static-assets/images/new-design/download-new-hover.svg);
    --refresh-icon-primary: url(../static-assets/images/new-design/refresh-primary.svg);
    --refresh-icon-hover: url(../static-assets/images/new-design/refresh-hover.svg);
    --dropdown-arrow-icon: url(../static-assets/images/new-design/angleRight2.svg);

    --certificate-type-client-icon: url(../static-assets/images/new-design/client-cert.svg);
    --certificate-type-key-server-icon: url(../static-assets/images/new-design/key-server-cert.svg);
    --certificate-type-no-key-icon: url(../static-assets/images/new-design/no-key-hsm-cert.svg);
    --certificate-type-managed-icon: url(../static-assets/images/new-design/managed-cert.svg);
    --warning-tooltip-bg-color: #fff7eb;
    --warning-tooltip-text-color: #ca5e00;

    /** Badge */
    --badge-text-color: #6b7280;
    --badge-bg-color: #eff2f3;
    --badge-error-color: #b60000;
    --badge-error-bg-color: #ffacb1;
    --new-badge-bg-color: #cef3ff;
    --new-badge-color: #087ea5;
    --app-sp-badge-color: var(--new-badge-color);
    --app-sp-badge-bg-color: #d6f1fe;

    /*POV banner */
    --pov-banner-icon: url(../static-assets/images/brand-logo-light.svg);
    --pov-banner-close-icon: url(../static-assets/images/new-design/pov-banner-close.svg);

    /* Attack Story */
    --as-hacker-icon: url(../static-assets/images/new-design/hacker.svg);
    --as-shield-icon: url(../static-assets/images/new-design/shield.svg);
    --as-warning-low-icon: url(../static-assets/images/new-design/warning-low.svg);
    --as-warning-medium-icon: url(../static-assets/images/new-design/warning-medium.svg);
    --as-warning-high-icon: url(../static-assets/images/new-design/warning-high.svg);
    --as-warning-custom-icon: url(../static-assets/images/new-design/warning-custom.svg);
    --as-clock-icon: url(../static-assets/images/new-design/clock.svg);
    --as-attack-rays-icon: url(../static-assets/images/new-design/rays.svg);
    --as-arrow-icon: url(../static-assets/images/new-design/arrow.svg);
    --as-stop-icon: url(../static-assets/images/new-design/as-stop.svg);
    --as-shield-success-icon: url(../static-assets/images/new-design/shield-success.svg);
    --as-summary-hacker-border-color: #ff6a6a;
    --as-summary-hacker-box-shadow: 0px 1px 6px rgba(255, 174, 174, 0.4);
    --as-summary-sensitivity-low-color: #cc8aff;
    --as-summary-sensitivity-medium-color: #a93cff;
    --as-summary-sensitivity-high-color: #6f00c7;
    --as-summary-sensitivity-custom-color: #200039;
    --as-summary-blocked-events-color: #fd374e;
    --as-summary-blocking-time-color: #4872db;
    --as-title-primary-color: #264769;
    --as-title-secondary-color: #6b7280;
    --as-hacker-icon-box-shadow: 0px 0px 3px #ffaeae, 0px 1px 28px #f59797d1;
    --as-shield-success-icon-box-shadow: 0px 0px 3px #6ec089, 0px 1px 20px 7px rgb(110 243 153 / 45%);
    --as-attacks-carousel-border-color: #c5c7d0;
    --as-attacks-carousel-slide-btn: url(../static-assets/images/new-design/angleRight2.svg);
    --as-attacks-carousel--disabled-slide-btn: url(../static-assets/images/new-design/angleRight2-disabled.svg);
    --as-section-bg-color: white;
    --as-attack-count-color: #df001a;

    /* Widgets repository */
    --widgets-repository-bg-color: #f1f8fa;

    /* Date Picker */
    --date-picker-shadow: 0 0 0 1px rgb(16 22 26 / 10%), 0 2px 4px rgb(16 22 26 / 20%), 0 8px 24px rgb(16 22 26 / 20%);
    --date-picker-bg-color: #{$white};
    --date-picker-border-color: #c0cddf;
    --date-picker-text-color: #6b7280;
    --date-picker-item-hover-bg-color: #e5e8eb;
    --date-picker-selected-day-text-color: #0e0ada;
    --date-picker-today-text-color: #264769;
    --date-picker-next-prev-arrow-color: #211dd9;
    --date-picker-time-bg-color: #e5e8eb;
    --date-picker-time-text-color: #6b7280;
    --date-picker-old-and-new-date-text-color: rgba(104, 127, 150, 0.3);
    --date-picker-arrow-fill-color: #{$white};
    --date-picker-selected-range-bg-color: rgba(26, 105, 213, 0.1);
    --date-picker-selected-range-text-color: #1a5bb7;

    /* Form */
    --form-button-cancel-bg-color: #e6e6e6;
    --form-button-cancel-hover-bg-color: #ccd3d9;
    --form-button-retry-bg-color: #ffa842;
    --form-button-retry-hover-bg-color: rgba(255, 204, 143, 0.8);

    /* Modal */
    --modal-backdrop-color: rgba(196, 196, 196, 0.8);
    --modal-bg-color: #{$white};

    /* Radio button */
    --radio-button-border-color: rgba(33, 29, 217, 0.5);
    --radio-button-border-hover-color: #211dd9;
    --radio-button-border-selected-hover-box-shadow: 0px 0px 2px rgba(33, 29, 217, 0.5);
    --radio-button-selected-bg-color: #211dd9;
    --radio-button-disabled-color: #e6e6eb;

    /*App summary item*/
    --app-summary-item-bg-color: #{$new_gray_300};
    --app-summary-item-status-disabled-bg-color: #{$dark-gray-300};
    --app-summary-item-status-not-available-bg-color: #{$gray-900};

    /* Editor */
    --editor-bg-color: #{$white};
    --editor-active-line: rgb(245, 245, 245);
    --editor-line-number-column: #e8e8e8;
    --editor-focus-active-line: rgb(255, 255, 204);
    --editor-selection-color: rgb(181, 213, 255);
    --editor-highlight-color: #7795f6;

    /* Progress bar */
    --progress-bar-bg-color: #f1f8fa;
    --progress-bar-border-color: #e6e6e6;

    /* General */
    --activity-log-drawer-tab-hover-bg-color: #e5e8eb;
    --security-events-filter-control-bg-color: #e6e6e6;
    --security-events-action-blocked-color: rgba(246, 14, 14, 0.8);
    --button-bg-color: #e6e6e6;
    --activity-log-id-label: #300067;
    --link-text-color: #2a6d96;
    --try-unified-portal-tooltip-new-word-color: #0e0ada;
    --try-unified-portal-btn-box-shadow: 0px 0px 5px rgba(3, 0, 138, 0.5);

    /* Licence */
    --licence-image: url(../static-assets/images/new-design/licence-img.svg);

    /* out of plan BE color */
    --out-of-plan-bg-color: #c7c7c7cc;

    /* new button  color */
    --outlined-btn-bg-color: #e5e8eb;
    --outlined-btn-color: #6b7280;
    --outlined-btn-bg-hover-color: #cbd4db;
    --outlined-btn-hover-color: #0e0ada;

    /* certificate item */
    --certificate-type-client-color: #4745d4;
    --certificate-type-client-bg-color: #b4b2fb40;
    --certificate-type-key-server-color: #db8300;
    --certificate-type-key-server-bg-color: #fff3e2;
    --certificate-type-no-key-color: #ff475d;
    --certificate-type-no-key-extra-color: #6b7280;
    --certificate-type-no-key-bg-color: #feebf2;
    --certificate-type-managed-bg-color: #d6f1fe;
    --certificate-type-managed-color: #036998;

    /* application type */
    --application-type-in-line-color: #6b7280;
    --application-type-in-line-bg-color: #e5e8eb;

    --application-type-oop-color: #036998;
    --application-type-oop-bg-color: #e3e9fd;
    --application-type-oop-icon: url(../static-assets/images/new-design/oop.svg);

    --application-type-bot-only-color: #ff9f11;
    --application-type-bot-only-bg-color: #ffc46d33;
    --application-type-bot-only-icon: url(../static-assets/images/new-design/botm_standalone.svg);

    --application-type-sni-color: #481490;
    --application-type-sni-bg-color: #dfcff4;

    --application-type-no-key-color: var(--certificate-type-no-key-color);
    --application-type-no-key-bg-color: var(--certificate-type-no-key-bg-color);
    --application-type-no-key-icon: var(--certificate-type-no-key-icon);

    /* Key Server Widgets */
    --key-server-widget-title-color: #4743f8;

    /* icons */
    --clock2-icon: url(../static-assets/images/clock2.svg);
    --rule-icon: url(../static-assets/images/rule.svg);
    --book-icon: url(../static-assets/images/book.svg);
    --cancel-icon: url(../static-assets/images/cancel.svg);
    --sync-alt-icon: url(../static-assets/images/sync-alt.svg);
    --actor-card-icon: url(../static-assets/images/actor-card.svg);
    --actor-blocked-icon: url(../static-assets/images/actor-blocked.svg);
    --sessions-seen-icon: url(../static-assets/images/logout2.svg);
    --ip-icon: url(../static-assets/images/ip.svg);
    --rules-icon: url(../static-assets/images/rules.svg);

    /*security hardening*/
    --security-hardening-icon: url(../static-assets/images/securityHardening.svg);

    /* bla */
    --bla-dashboard-badge-text-color: #2286cf;
    --bla-dashboard-badge-bg-color: #eaf6ff;
    --bla-dashboard-item-icon-bg-color: #dfe2e6;
    --bla-dashboard-divider-color: #2647691a;
    --bla-dashboard-card-bg-color: rgba(38, 71, 105, 0.03);

    /* botm bypass tags */
    --bot-bypass-fully-text-color: #fe001e;
    --bot-bypass-fully-bg-color: #ffebec;
    --bot-bypass-partially-text-color: #ca5e00;
    --bot-bypass-partially-bg-color: #fff7eb;

    [data-theme='dark'] {
        --text-color: #d4d6db;
        --text-secondary-color: #d4d6db;
        --page-bg-color: #{$new_dark_gray_500};

        /** Tooltip **/
        --tooltip-bg-color: #3d4149;
        --tooltip-text-color: #d4d6db;
        --tooltip-border-color: rgba(212, 214, 219, 0.5);
        --tooltip-button-hover-bg-color: #6b7176;
        --warning-tooltip-bg-color: #3d2500;
        --warning-tooltip-text-color: #ffb549;

        /** Profile Menu **/
        --profile-menu-image-hover-bs: 0px 0px 8px 2px #{rgba($new_white, 0.5)};
        --profile-menu-item-hover-bg-color: #2c2d31;
        --profile-menu-item-hover-border-color: rgba(233, 241, 243, 0.3);
        --profile-menu-item-active-border-color: #111113;
        --profile-menu-account-icon: url(../static-assets/images/account2-dark.svg);
        --profile-menu-logout-icon: url(../static-assets/images/exit-dark.svg);
        --profile-menu-icon-hover-bg-color: var(--generic-icon-hover-bg-color);

        /** Dropdown **/
        --dropdown-border-color: #d4d6db;
        --dropdown-hover-border-color: #{$new_blue_800};
        --dropdown-active-border-color: #{$new_blue_800};
        --dropdown-input-text-color: #d4d6db;
        --dropdown-list-bg-color: #191b1e;
        --dropdown-list-box-shadow: 0px 1px 4px #{rgba($new_white, 0.15)};
        --dropdown-list-border-color: rgba(212, 214, 219, 0.5);
        --dropdown-item-hover-bg-color: #2c2d31;
        --dropdown-item-active-bg-color: #111113;
        --dropdown-item-border-color: rgba(233, 241, 243, 0.3);
        --dropdown-item-text-color: #d4d6db;
        --dropdown-item-service-provider-text-color: #3b77ee;
        --dropdown-disabled-bg-color: #353739;
        --dropdown-disabled-text-color: rgba(212, 214, 219, 0.8);
        --dropdown-primary-arrow-icon: url(../static-assets/images/new-design/chevron-dark.svg);
        --dropdown-hover-arrow-icon: url(../static-assets/images/new-design/chevron-selected-dark.svg);
        --dropdown-selected-arrow-icon: url(../static-assets/images/new-design/chevron-selected-dark.svg);
        --dropdown-disabled-arrow-icon: url(../static-assets/images/new-design/chevron-disabled-dark.svg);
        --dropdown-scroller-color: #6b7176;
        --dropdown-label-color: #d4d6db;
        --dropdown-placeholder-color: rgba(212, 214, 219, 0.7);
        --dropdown-disabled-label-color: rgba(212, 214, 219, 0.8);
        --dropdown-toggle-bg-color: #343434;
        --dropdown-toggle-hamburger-slice-bg-color: #8c8c8c;

        /* Checkbox */
        --checkbox-border-disabled-color: #575757;
        --checkbox-selected-color: #2555d1;
        --checkbox-label-color: #d4d6db;

        /* Control */
        --control-border-color: #e7e7e8;
        --control-hover-border-color: #2555d1;
        --control-selected-border-color: #2555d1;
        --control-text-color: #d4d6db;
        --control-placeholder-color: rgba(212, 214, 219, 0.7);
        --control-disabled-bg-color: #353739;
        --control-disabled-text-color: rgba(212, 214, 219, 0.5);

        /* Search */
        --search-primary-border-color: #e7e7e8;
        --search-hover-border-color: #{$new_blue_800};
        --search-active-border-color: #{$new_blue_800};
        --search-icon: url(../static-assets/images/new-design/search-icon-small-dark.svg);
        --search-close-icon: url(../static-assets/images/new-design/close-icon-small-dark.svg);
        --search-input-text-color: #d4d6db;
        --search-input-placeholder-color: #d4d6db;

        /** Header **/
        --header-bg-color: #{$new_dark_gray_500};
        --header-recommendations-icon: url('../static-assets/images/bulb-dark.svg');
        --header-activity-logs-icon: url('../static-assets/images/logs-dark.svg');
        --header-activity-logs-pending-icon: url('../static-assets/images/logs-pending.svg');
        --header-security-alerts-icon: url('../static-assets/images/bell-dark.svg');
        --header-drawers-icons-active-bg-color: #484a4d;
        --header-security-alerts-active-bg-color: #2f1c00;

        /** Sidebar **/
        --sidebar-bg-color: #{$new_dark_gray_600};
        --sidebar-item-bg-color: #303135;
        --sidebar-item-hover-bg-color: #696a6e;
        --sidebar-tooltip-item-hover-bg-color: #595b5f;
        --sidebar-tooltip-bg-color: #1f2023;
        --toggle-sidebar-btn-bs: 0px 0px 6px 2px rgba(255, 101, 101, 0.5);

        /** Footer **/
        --footer-bg-color: #{$new_dark_gray_500};
        --footer-text-color: #{$new_white};
        --footer-bolded-text-bg-color: #3b77ee;
        --footer-bolded-text-hover-bs-color: #52cbff;

        /* Tabs */
        --tab-active-line: #3b77ee;
        --tab-active-text-color: #6dcffc;

        /* Table */
        --table-bg-color: #1a1c1e;
        --table-row-border-color: rgba(233, 241, 243, 0.3);
        --table-row-hover-bg-color: #28282a;
        --table-row-active-bg-color: #{$new_black};
        --table-expanded-row-bg-color: #{$new_black};
        --table-header-column-text-color: #d4d6db;
        --table-data-column-text-color: #d4d6db;

        /* Section */
        --section-bg-color: #1a1c1e;
        --section-header-hover-bg-color: #28282a;
        --section-header-active-bg-color: var(--section-bg-color);
        --section-header-desc-text-color: rgba(212, 214, 219, 0.7); //#d4d6db 70%
        --section-icon-hover-bg-color: var(--generic-icon-hover-bg-color);

        /* Card */
        --card-bg-color: #1a1c1e;
        --card-separator-color: #{rgba($gray_200, 0.3)};
        --card-hover-bg-color: #28282a;
        --card-inner-bg-color: var(--card-bg-color);
        --card-summary-bg-color: #{$new_dark_gray_400};
        --card-header-desc-text-color: rgba(212, 214, 219, 0.7); //#d4d6db 70%
        --card-icon-hover-bg-color: var(--generic-icon-hover-bg-color);

        /** Icons **/
        --certificate-disabled-icon: url(../static-assets/images/new-design/certificate-disabled-dark.svg);
        --add-button-bg-color-disabled: #{$add-icon-disabled-bg-color-dark};
        --add-button-box-shdow-hover: 0px 0px 5px 2px rgba(53, 59, 210, 0.6);
        --plus-icon-disabled: url(../static-assets/images/new-design/disabled-plus-icon-dark.svg);
        --discard-delete-icon-primary: url(../static-assets/images/new-design/delete-restore-primary-dark.svg);
        --discard-delete-icon-hover: url(../static-assets/images/new-design/delete-restore-hover-dark.svg);

        --generic-icon-hover-bg-color: #{$new_dark_gray_1000};

        --csv-icon-primary: url(../static-assets/images/new-design/csv-export-primary-dark.svg);
        --csv-icon-hover: url(../static-assets/images/new-design/csv-export-hover-dark.svg);
        --download-icon-primary: url(../static-assets/images/new-design/download-primary-dark.svg);
        --download-icon-hover: url(../static-assets/images/new-design/download-hover-dark.svg);
        --download-icon-new-primary: url(../static-assets/images/new-design/download-new_dark.svg);
        --download-icon-new-hover: url(../static-assets/images/new-design/download-new-hover.svg);
        --refresh-icon-primary: url(../static-assets/images/new-design/refresh-primary-dark.svg);
        --refresh-icon-hover: url(../static-assets/images/new-design/refresh-hover-dark.svg);
        --dropdown-arrow-icon: url(../static-assets/images/new-design/angleRight2-dark.svg);

        --certificate-type-client-icon: url(../static-assets/images/new-design/client-cert-dark.svg);
        --certificate-type-key-server-icon: url(../static-assets/images/new-design/key-server-cert-dark.svg);
        --certificate-type-no-key-icon: url(../static-assets/images/new-design/no-key-hsm-cert-dark.svg);
        --certificate-type-managed-icon: url(../static-assets/images/new-design/managed-cert-dark.svg);

        /** Badge */
        --badge-text-color: #d4d6db;
        --badge-bg-color: #414346;
        --badge-error-color: #b60000;
        --badge-error-bg-color: #ffacb1;
        --new-badge-bg-color: #00516a;
        --new-badge-color: #2cccff;
        --app-sp-badge-color: var(--new-badge-color);
        --app-sp-badge-bg-color: #0f3d52;

        /* Attack Story */
        --as-warning-low-icon: url(../static-assets/images/new-design/warning-low-dark.svg);
        --as-warning-medium-icon: url(../static-assets/images/new-design/warning-medium-dark.svg);
        --as-warning-high-icon: url(../static-assets/images/new-design/warning-high-dark.svg);
        --as-warning-custom-icon: url(../static-assets/images/new-design/warning-custom-dark.svg);
        --as-summary-sensitivity-low-color: #dfcff4;
        --as-summary-sensitivity-medium-color: #c19ff2;
        --as-summary-sensitivity-high-color: #cc8aff;
        --as-summary-sensitivity-custom-color: #a93cff;
        --as-attacks-carousel-slide-btn: url(../static-assets/images/new-design/angleRight2-dark.svg);
        --as-section-bg-color: transparent;

        /* Widgets repository */
        --widgets-repository-bg-color: #191b1e;

        /* Date Picker */
        --date-picker-shadow: 0 0 0 1px rgb(255 255 255 / 10%), 0 2px 4px rgb(77 76 76 / 20%), 0 8px 24px rgb(123 123 123 / 20%);
        --date-picker-bg-color: #191b1e;
        --date-picker-border-color: rgba(212, 214, 219, 0.5);
        --date-picker-text-color: #d4d6db;
        --date-picker-item-hover-bg-color: #42474b;
        --date-picker-selected-day-text-color: #6dcffc;
        --date-picker-today-text-color: #3b77ee;
        --date-picker-next-prev-arrow-color: #3b77ee;
        --date-picker-time-bg-color: #484a4d;
        --date-picker-time-text-color: #d4d6db;
        --date-picker-old-and-new-date-text-color: #d4d6db;
        --date-picker-arrow-fill-color: #191b1e;
        --date-picker-selected-range-bg-color: #24282d;
        --date-picker-selected-range-text-color: #3b77ee;

        /* Form */
        --form-button-cancel-bg-color: transparent;
        --form-button-cancel-hover-bg-color: #ccd3d9;
        --form-button-retry-bg-color: #d7974b;
        --form-button-retry-hover-bg-color: rgba(255, 179, 89, 0.6);

        /* Modal */
        --modal-backdrop-color: rgba(73, 70, 70, 0.9);
        --modal-bg-color: #212225;

        /* Radio button */
        --radio-button-border-color: #{$new_blue_800};
        --radio-button-border-hover-color: #2e68ff;
        --radio-button-border-selected-hover-box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.5);
        --radio-button-selected-bg-color: #{$new_blue_800};
        --radio-button-disabled-color: #575757;

        /*App summary item*/
        --app-summary-item-bg-color: transparent;
        --app-summary-item-status-disabled-bg-color: #{$gray-900};
        --app-summary-item-status-not-available-bg-color: #{$dark-gray-300};

        /* Editor */
        --editor-bg-color: #1a1c1e;
        --editor-line-number-column: #4b4b4b;
        --editor-active-line: rgb(90 88 88 / 40%);
        --editor-focus-active-line: rgba(212, 214, 219, 0.5);
        --editor-selection-color: rgb(173 174 175 / 20%);
        --editor-highlight-color: rgb(109 207 252 / 50%);

        /* Progress bar */
        --progress-bar-bg-color: #42474b;
        --progress-bar-border-color: #42474b;

        /* General */
        --activity-log-drawer-tab-hover-bg-color: #42474b;
        --security-events-filter-control-bg-color: #42474b;
        --security-events-action-blocked-color: #ff2b44;
        --button-bg-color: #{$input-bg-color};
        --activity-log-id-label: #c19ff2;
        --link-text-color: #09aefa;
        --try-unified-portal-tooltip-new-word-color: #6dcffc;
        --try-unified-portal-btn-box-shadow: 0px 0px 8px rgba(191, 189, 255, 0.8);

        /* Licence */
        --licence-image: url(../static-assets/images/new-design/licence-img-dark.svg);

        /* out of plan BE color */
        --out-of-plan-bg-color: #535353;

        /* new button  color */
        --outlined-btn-bg-color: #414346;
        --outlined-btn-color: #d4d6db;
        --outlined-btn-bg-hover-color: #cbd4db;
        --outlined-btn-hover-color: #0e0ada;

        /* certificate item */
        --certificate-type-client-color: #ecebfe;
        --certificate-type-client-bg-color: #3332a1;
        --certificate-type-key-server-color: #ffdda9;
        --certificate-type-key-server-bg-color: #a5690f;
        --certificate-type-no-key-color: #ffc2c9;
        --certificate-type-no-key-extra-color: #d4d6db;
        --certificate-type-no-key-bg-color: #90093b;
        --certificate-type-managed-bg-color: #124a63;
        --certificate-type-managed-color: #3bbefb;

        /* application type */
        --application-type-in-line-color: #d4d6db;
        --application-type-in-line-bg-color: #4f5154;

        --application-type-oop-color: #ecebfe;
        --application-type-oop-bg-color: #3332a1;
        --application-type-oop-icon: url(../static-assets/images/new-design/oop-dark.svg);

        --application-type-bot-only-color: #ffdda9;
        --application-type-bot-only-bg-color: #a5690f;
        --application-type-bot-only-icon: url(../static-assets/images/new-design/botm_standalone-dark.svg);

        --application-type-sni-color: #ebe0fb;
        --application-type-sni-bg-color: #4c3b8e;

        --application-type-no-key-color: var(--certificate-type-no-key-color);
        --application-type-no-key-bg-color: var(--certificate-type-no-key-bg-color);
        --application-type-no-key-icon: var(--certificate-type-no-key-icon);

        /* Key Server Widgets */
        --key-server-widget-title-color: #3b77ee;

        /* icons */
        --clock2-icon: url(../static-assets/images/clock2-dark.svg);
        --rule-icon: url(../static-assets/images/rule-dark.svg);
        --book-icon: url(../static-assets/images/book-dark.svg);
        --cancel-icon: url(../static-assets/images/cancel-dark.svg);
        --sync-alt-icon: url(../static-assets/images/sync-alt-dark.svg);
        --actor-card-icon: url(../static-assets/images/actor-card-dark.svg);
        --actor-blocked-icon: url(../static-assets/images/actor-blocked-dark.svg);
        --sessions-seen-icon: url(../static-assets/images/logout2-dark.svg);
        --ip-icon: url(../static-assets/images/ip-dark.svg);
        --rules-icon: url(../static-assets/images/rules-dark.svg);

        /* bla */
        --bla-dashboard-badge-text-color: #2cccff;
        --bla-dashboard-badge-bg-color: #00516a;
        --bla-dashboard-item-icon-bg-color: #414346;
        --bla-dashboard-divider-color: #e9f1f34d;
        --bla-dashboard-card-bg-color: #131416;

        /*security hardening*/
        --security-hardening-icon: url(../static-assets/images/securityHardening-dark.svg);

        /* botm bypass tags */
        --bot-bypass-fully-text-color: #ff2b44;
        --bot-bypass-fully-bg-color: #3f1218;
        --bot-bypass-partially-text-color: #ffd057;
        --bot-bypass-partially-bg-color: #3d2500;
    }
}
